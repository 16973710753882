import React ,{useState,useEffect} from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TitleHeader from '../components/TitleHeader';
import "../css/general.css";
import SimpleBackdrop from"../components/SimpleBackdrop";
import { get_all_users } from '../Functions/admin_functions';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SearchRounded } from '@mui/icons-material';
import Add_user_modal from '../components/Add_user_modal';
import User_ViewTable from '../components/User_ViewTable';
function User_managment() {
 
  const [AddUserModal, setModal ] = useState(false);
  const [allUser,setUsers]=useState(null);
  // for handling submit for search

  const toogleModal=()=>
  {
    setModal(!AddUserModal);
    get_users_data();
  }
  //for setting all user data from the server
const get_users_data=()=>{
  get_all_users().then((res)=>{
    setUsers(res);
    console.log(res);
   
})
}
//const refresh data
const refresh_data=()=>
{
  setUsers(null)
  get_users_data();
}
useEffect(() => {
 get_users_data();
}, [])

  return (
    <div className='container-fluid  '>
      <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<PeopleAltIcon/>} title="User Management"/>
      </div>

      <div className='container-fluid admin_container_color'>
          <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                    <button className='btn btn-primary fw-bold mx-2' onClick={toogleModal}>Manage Role <PersonAddAltIcon/></button>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data}>Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
          {allUser ? <User_ViewTable result={allUser} refresh_data={refresh_data} className="text-dark"/>:<SimpleBackdrop/>}
        </div>

      </div>

      </div>
      
       <Add_user_modal modal_status={AddUserModal} Modal_toggle={toogleModal}/>
    </div>
  )
}

export default User_managment