import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../Apis/api';
import Alert_Modals from '../../components/Alert_Modals';
function Catagory_ViewTable({result,refresh_data}) {
    const [alert_dialog,setDialog]=useState(false)
      const[alert_text,setText]=useState("");
      const[alert_type,setType]=useState();
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const handel_delete=async(catagory_id)=>{
        if( window.confirm("Are you sure you want to remove this Catagory?"))
        {

            try {
         const response= await api.delete(`/Material/Delete/Catagory/${catagory_id}`)
         if(response.data.status=="success")
         {
             setDialog(true);
             setType("success")
             setText("Catagory has been successfuly removed")
             setTimeout(() => {
                 setDialog(false);
                 refresh_data()
               }, 1500);
         }
        } catch (error) {
            console.log(error);
            setDialog(true);
            setType("error");
            setText("Internal Server Error occured while trying to remove the Catagory")
            setTimeout(() => {
             setDialog(false);
             refresh_data()
           }, 1500);
         }
        }
      }
      const columns=[
        {label:"Catagory name",name:"catagory_name"},
        {label:"Action",name:"catagory_id", options:{ filter: false,
            customBodyRender:(value)=>(
               
                   <>
                    <span className='btn btn-danger mx-1 my-1'onClick ={()=>handel_delete(value)}><DeleteIcon/></span>
                   </>
               
            )
    }},]
    if(  result!== undefined && result !== null )  { 
        return (
            <>
            
          {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
          <MUIDataTable 
          title={"All Catagory"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        /></>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no Catagory found on the system !"
            />
            )
          }
      }


export default Catagory_ViewTable