import React from 'react'
import MUIDataTable from "mui-datatables";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector,useDispatch } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import moment from "moment";
import Alert_Modals from '../../components/Alert_Modals';
import { Edit } from '@mui/icons-material';
import { deactivate_account } from '../Functions/admin_functions';
import { set_selected_user } from '../../Store/Action';
function User_ViewTable({result,refresh_data}) {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const handel_edit=(account_id)=>
        {
            const [account]=result.filter((user)=>user.account_id==account_id);
            dispatch(set_selected_user(account));
           navigate(`/Admin/Edit_Users/${account_id}`)
        }
        const handel_Deactivation=(account_id)=>
        {
        
            deactivate_account(account_id).then((res)=>{
                refresh_data();
            })
        }
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };

      const columns = [
       
        {label:"Firstname",name:"firstname"},
        {label:"Lastname",name:"lastname"},
        {label:"Gender",name:"gender"},
        {label:"Phonenumber",name:"phonenumber"},
        {label:"Email",name:"email"},
        {label:"Role",name:"role",options:{
            customBodyRender:(value)=>{
                if(value=="Content creator")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="Admin")
                {
                    return (
                        <span className='btn btn-primary text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
                if(value=="Active")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="inactive")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"Start Date",name:"start_date",
            options:{
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"account_id", options:{
                customBodyRender:(value)=>(
                   
                       <>
                        <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_edit(value)}>Edit<Edit  className=' btn-success '/></span>
                        <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                       </>
                   
                )
        }},

    ];
   
    if(  result!== undefined && result !== null )  { 
  return (
    <MUIDataTable 
    title={"User Account report"}
    data={result}
    columns={columns}
    options={options}
    className="text-color"
  />
  )}
  else
  {
    return (
    <Alert_Modals type="error" 
    text="Sorry no User found on the system !"
      />
      )
    }
}

export default User_ViewTable
