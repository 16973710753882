import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import Alert_Modals from '../../components/Alert_Modals';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector,useDispatch } from 'react-redux';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { useNavigate } from 'react-router-dom';
import { get_member_account } from '../../functions/api_call_functions';
import { set_selected_member } from '../../Store/Action';
import api from '../../Apis/api';
import { activate_account, activate_membership_status, sent_request_result_email } from '../Functions/admin_functions';
function Request_View({result,refresh_data,account}) 
{
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const dispatch=useDispatch();
    const navigate=useNavigate();

const options = {
    filterType: 'checkbox',
    pagination:true,
    responsive:'stacked',
    rowsPerPage:5,
    rowsPerPageOptions:[5,10,15,20],
    rowHover:true,
    selectableRowsHeader:false,
    selectableRows:false
  };
  const handel_generate_setting=(member_id)=>{
    get_member_account(member_id).then((res)=>{
        if(res.status=="success")
        {
            dispatch(set_selected_member(res.user))
            navigate("/Admin/Member/role-config");
        }
        else{
            setText("Error occured while try to fetch  account please try again later ")
            setType("error");
            setDialog(true);
            setTimeout(() => {
              setDialog(false);
               }, 3000);
        }

    })
}
const handel_request=async(request_id,status,email,member_id)=>{
    try {
        const  response=await api.put(`/Request/update/${request_id}/${status}/${account}`)
        if(response.data.status=="success" && response.data.result==1)
        {       
            activate_membership_status(member_id,status).then((res)=>{
                if(res.status=="success" && res.result==1)
                {       
                   sent_request_result_email(member_id,status,email).then((res)=>{
                    if(res.status=="success")
                    {   
                        setText(res.msg);
                        setType("success");
                        setDialog(true)
                        setTimeout(() => {
                            setDialog(false);
                            refresh_data();
                            ;
                             }, 2000 );
                            
                             
                             
                    }
                    else{
                        setText(res.msg);
                        setType("error");
                        setDialog(true)
                        setTimeout(() => {
                            setDialog(false);
                            refresh_data();
                            ;
                             }, 2000 );
                    }

                   })
                }
                else{
                    setText("Error while trying to update Membership Status");
                    setType("error");
                    setDialog(true);
                    setTimeout(() => {
                        setDialog(false);
                         }, 1000);
                }

            })
          
            
        }
        else{
            setText("Error while trying to update request Status");
            setType("error");
            setDialog(true);
            setTimeout(() => {
                setDialog(false);
                 }, 1000);
        }
    } catch (error) 
    {
        console.log(error);
        setText(error.response.data.error);
        setType("error");
        setDialog(true);
        setTimeout(() => {
            setDialog(false);
             }, 1000);
    }
}
const handel_rejection=(request_id)=>{

}
const columns=[
    {label:"ID",name:"account_id" ,options: { filter: false,}},
    {label:"Firstname",name:"firstname" ,options: { filter: false,}},
    {label:"Lastname",name:"lastname" ,options: { filter: false,}},
    {label:"Phonenumber",name:"phonenumber" ,options: { filter: false,}},
    {label:"Email",name:"email" ,options: { filter: false,}},
    {label:"Created Date",name:"created_date",
    options:{
        filter: false,
        customBodyRender:(value)=>(
            moment(value ).format('YYYY-MM-DD')
        ) }
    },
    {label:"Status",name:"request_status", options:{
        customBodyRender:(value)=>{
            if(value=="Pending")
            { 
            return (
                <span className='badge bg-primary'> {value}</span>
            )
            }
            else if(value=="Approved")
            {
                return (
                    <span className='badge bg-success'> {value}</span>
                )
            }
            else if(value=="Rejected")
            {
                return (
                    <span className='badge bg-danger'> {value}</span>
                )
            }
               
        }
}},
    {label:"Action",name:"request_id", options:{
        filter: false,
        customBodyRender:(value,tableMeta)=>{
           
           return (
          
               <div>
                <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_request(value,"Approved",tableMeta.rowData[4],tableMeta.rowData[0])}>Approve <CheckCircleIcon  className=' btn-success '/></span>
                <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_request(value,"Rejected",tableMeta.rowData[4],tableMeta.rowData[0])}>Reject <UnpublishedIcon  className='btn-danger'/></span>
                <span className='btn btn-primary mx-1 my-1' onClick ={()=>handel_generate_setting(tableMeta.rowData[0])}><SettingsSuggestIcon/></span>
               </div>
           
        )}
}},
]
if(  result!== undefined && result !== null )  { 
    return (
      <>
    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

      <MUIDataTable 
      title={"Membership Approval Request report"}
      data={result}
      columns={columns}
      options={options}
      className="text-color"
    />
     </>
    )}
    else
    {
       return (<Alert_Modals type="error" 
        text="Sorry no Pendding request found on the system !"
          />)
      }


}
export default Request_View