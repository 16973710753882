import React, { useEffect, useState } from 'react'
import  "../Css_for_components/Pricing.css";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

function Pricing() {
    useEffect(()=>{
        const options={
            rootMargin:"0px",
            threshold:0.2
        }
     const title=document.getElementById("pricing_card_title");
     const style=document.getElementById("pricing_details");
     const observer=new IntersectionObserver((enteries)=>{
        enteries.forEach((entry)=>{
            if(entry.isIntersecting)
                { 
                    
                if(entry.target.id=='pricing_card_title')
               {
                entry.target.classList.add("pricing_fade_in");
                // observer.unobserve(entry.target);
                }
                else if (entry.target.id=="pricing_details")
                {
                    entry.target.classList.add("pricing_fade_in");
                }
                // observer.unobserve(entry.target);
                }
        })
      
       
    },options)
    observer.observe(title);
    observer.observe(style);
    },[])
  return (
    
    <div className='container-fluid  rounded mt-4'>
            <div className='container pricing_title_hidden  'id='pricing_card_title'>
                <h5 className='pricing_card_title' >PRICING</h5>
                <div className=" row mt-2 p-2 ">
                <p className='text-secondary text-center'> Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>

                </div>
            </div>
            <div className='container pricing_hidden' id="pricing_details">
               
            <div className='row mt-4'>
                <div className='col-sm-12 col-lg-3 mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>Individual</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>500</span> / Year</h5>
                              
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-3 mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>Individual Life Time</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>3000</span> / <AllInclusiveIcon/></h5>
                              
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-3 mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>Organization</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>3000</span> / Year</h5>
                              
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                               <div className='d-flex'>
                                <h4>Aida dere</h4>
                               </div>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
            </div>
            </div>
           
    </div>
  )
}

export default Pricing