import React, { useEffect, useState } from 'react'
import  "../Css_for_components/contact_us.css";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Alert_Modals from './Alert_Modals';
import PlaceIcon from '@mui/icons-material/Place';
import { check_site_config } from '../Admin/Functions/admin_functions';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import api from '../Apis/api';
function Contact_us_form() {
  const [form_info,setInfo]= useState({
    name:"",
    email:"",
    subject:"",
    message:"",
    sent_time:new Date()

  });
  const [alert_dialog,setDialog]=useState(false)
  const[alert_text,setText]=useState("");
  const[alert_type,setType]=useState("");
  const [siteConfig,setData]=useState({

    primary_email:"",
    secondary_email:"",
    primary_phonenumber:"",
    secondary_phonenumber:"",
    Address:"",
    Description:"",
    twitter_link:"",
    facebook_link:"",
    telegram_link:"",
    instagram_link:"",
    Likedin_link:""
    
})

  const onhandelChange=(e)=>{
      setInfo((pre)=>(
        {
          ...pre,
          [e.target.name]:e.target.value
        }
      ))
  }
  const onHandelSubmit =async(e)=>{
      e.preventDefault();
      try {
        const response= await api.post("/Messages/send-message/",{
          form_info
        })
        if(response.data.status=="success")
        {
          setText("Your Message was sent Successfuly")
          setType("success");
          setDialog(true);
          setTimeout(() => {
            setDialog(false);
            setInfo({
              name:"",
              email:"",
              subject:"",
              message:"",
              sent_time:new Date()
            })
       
        }, 3000);
        }
          
      } catch (error) {
        console.log(error);
        setText(error.response.data.error)
        setType("error");
        setDialog(true);
        setTimeout(() => {
          setDialog(false);
     
      }, 3000);
      }
  }
  useEffect(()=>{
    check_site_config().then((res)=>{
      if(res.result>0){
        const info= res.configuration[0];
        setData({
         primary_email:info.primary_email,
         secondary_email:info.secondary_email,
         primary_phonenumber:info.primary_phonenumber,
         secondary_phonenumber:info.secondary_phonenumber,
         Address:info.Address,
         Description:info.Description,
         twitter_link:info.twitter_link,
         facebook_link:info.facebook_link,
         telegram_link:info.telegram_link,
         instagram_link:info.instagram_link,
         Likedin_link:info.Likedin_link,
         id:info.id,
        })
      }
   
    }) 
    const options={
      rootMargin:"0px",
      threshold:1
  }
  const form= document.getElementById('contact_us_form')
  const contactUs_firstSection= document.getElementById ("contactUs_firstSection")
  const contactUs_secondSection= document.getElementById ("contactUs_secondSection")
  const contactUs_thirdSection= document.getElementById ("contactUs_thirdSection")
  const contactUs_fourthSection= document.getElementById ("contactUs_fourthSection")
  const observer=new IntersectionObserver((enteries)=>{
    enteries.forEach((entry)=>{
        if(entry.isIntersecting)
            { 
              entry.target.classList.add("contact_us_fade_in");
           
            observer.unobserve(entry.target);
            }
    })
  
   
},options)
observer.observe(form);
observer.observe(contactUs_firstSection);
observer.observe(contactUs_secondSection);
observer.observe(contactUs_thirdSection);
observer.observe(contactUs_fourthSection);


  },[])
  return (
    <div className='container'id="Contact_us">
       <div className='row'>
        <h4 className='contact_us_title fw-bolder'>Contact us</h4>
        </div>
        <div className='container rounded mt-3 mb-3'>
        <div className='row'>
            {/* Left container */}
            <div className='col-sm-12 col-lg-6 p-4 '>
              <div className='row'>
                <div className='col-sm-12 col-md-6 mt-3 '>
                    <div id="contactUs_firstSection" className='contact_us_items bg-light contactUs_firstSection rounded'>
                      <PlaceIcon className='contact_us_icon'/>
                      <h4>Address</h4>
                      <h5>{siteConfig.Address}</h5>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 mt-3'>
                <div id="contactUs_secondSection" className='contact_us_items bg-light contactUs_secondSection rounded'>
                      <PhoneIcon className='contact_us_icon'/>
                      <h4>Call Us</h4>
                      <h5>{siteConfig.primary_phonenumber}</h5>
                      <h5>{siteConfig.secondary_phonenumber}</h5>
                    </div>
              </div>
              </div>
              <div className='row mt-1'>
                <div className='col-sm-12 col-md-6 mt-3'>
                    <div id="contactUs_thirdSection" className='contact_us_items bg-light contactUs_thirdSection rounded'>
                      <EmailIcon className='contact_us_icon'/>
                      <h4>Email Us</h4>
                      <h5>{siteConfig.primary_email}</h5>
                      <h5>{siteConfig.secondary_email}</h5>
                    </div>
                </div>
                <div className='col-sm-12 h-100  col-md-6 mt-3'>
                <div id ="contactUs_fourthSection"className='contact_us_items bg-light contactUs_fourthSection rounded'>
                      <AccessTimeSharpIcon className='contact_us_icon'/>
                      <h4>Open Hours</h4>
                      <h5>8:00-6:00</h5>
                    </div>
              </div>
              </div>
            </div>

            {/* Right container form section*/}
            <div className='col-sm-12 col-lg-6  '>
            {alert_dialog && <Alert_Modals type={alert_type} text={alert_text} className="mt-3"/>}

                <form id="contact_us_form" className="p-4 bg-light rounded contact_us_form" onSubmit={onHandelSubmit}>
                <div class="row ">
                    <div class="col-sm-12 mb-1 mt-3 col-md">
                    <input type="text" class="form-control" placeholder="Your Name" required onChange={onhandelChange} value={form_info.name} name="name"/>
                    </div>
                    <div class="col-sm-12 mb-1 mt-3 col-md">
                    <input type="email" class="form-control" placeholder="Your email" required  onChange={onhandelChange} value={form_info.email} name="email"/>
                    </div>
                </div>
                <div class="mb-3 mt-3">
                    <input type="text" class="form-control"  placeholder="Subject" required onChange={onhandelChange} value={form_info.subject} name="subject"/>
                 </div>
                 <div class="mb-3 mt-3 ">
                   <textarea className='form-control' rows={5} cols={6} required  onChange={onhandelChange} value={form_info.message} name="message"/>

                   <button type="submit" className='send_message '>Send Message</button>
                 </div>
                </form>

            </div>

        </div>
        </div>
       
        </div>
  )
}

export default Contact_us_form