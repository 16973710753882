import api from "../../Apis/api"
import { get_today_date } from "../../functions/member_functions";
export const get_member_filter_date=async(start_date,end_date)=>
{ try {
    const response =await api.post("/Member/get-all/join-date/",{
        start_date,
        end_date
    })
   
    return({
        status:response.data.status,
        members:response.data.members,
        result:response.data.result
    })

} catch (error) {
    console.log(error);
    return({
        status:"fail",
        error:error.response.data.error
    })
}

}
export const get_members_registerd_today=async()=>
{
    try {
        const response =await api.get(`/Member/get-all/Today/${get_today_date()}`)
       
        return({
            status:response.data.status,
            members:response.data.members,
            result:response.data.result
        })
    
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}
export const get_members_registerd_Monthly=async()=>
    {
        var currentdate = new Date(); 
        const firtDateofMOnth=   currentdate.getFullYear() + "-"
        + (currentdate.getMonth()+1)  + "-" 
        + "01";
        try {
            const response =await api.get(`/Member/get-all/Monthly/${get_today_date()}/${firtDateofMOnth}`)
           
            return({
                status:response.data.status,
                members:response.data.members,
                result:response.data.result
            })
        
        } catch (error) {
            console.log(error);
            return({
                status:"fail",
                error:error.response.data.error
            })
        }
    }
    export const get_all_success_transactions_today=async()=>
    {
        try{
            const response= await api.get(`/transactions/get-all/success/${get_today_date()}`)
            return({
               status:response.data.status,
               transactions:response.data.transaction
           })
        }catch(error){
           console.log(error);
           return({
               status:"fail",
               error:error.response.data.error
           })
        }
    }
    export const  get_all_success_transaction_monthly=async()=>{
        var currentdate = new Date(); 
        const firtDateofMOnth=   currentdate.getFullYear() + "-"
        + (currentdate.getMonth()+1)  + "-" 
        + "01";
        try{
            const response= await api.get(`/transactions/get-all/success/${get_today_date()}/${firtDateofMOnth}`)
            return({
               status:response.data.status,
               transactions:response.data.transaction
           })
        }catch(error){
           console.log(error);
           return({
               status:"fail",
               error:error.response.data.error
           })
        }
    }