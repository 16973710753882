
import React, { useEffect, useState } from 'react'
import "../Css_for_components/Slide_hero.css"

function Slide_hero() {
    const[visible,setVisisble]=useState(false);

    // for toggleing the read button
    const toggle=()=>{
        setVisisble(!visible)
    }
    useEffect(()=>{
    const options={
        rootMargin:"0px",
        threshold:0.2
    }
    const item= document.getElementById("intro")
    const observer=new IntersectionObserver((enteries)=>{
     const entry= enteries[0];
      
      if(entry.isIntersecting)
       { item.classList.add("intro_loading");
      observer.unobserve(item);
       }
       
    },options)
    observer.observe(item)
    },[])
  return (
    <div>
      <div className='container-fluid p-0 carousel_container'>
      <div className='container-fluid '>
      <div id="demo" className="carousel slide h-100" data-bs-ride="carousel">

{/* <!-- Indicators/dots --> */}
{/* <div className="carousel-indicators">
  <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
  <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
  <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
</div> */}

{/* <!-- The slideshow/carousel --> */}
<div className="carousel-inner carousel_item ">
  <div className="carousel-item active  carousel_item">
  <div className=' '>
  <h2 className="display-4 fw-bold w-100 text-center container">Welcome to Ethiopian Evalutaion Association <span></span></h2>
          <p className="fw-bolder ">EEvA, the Ethiopian Evaluation Association, is a voluntary think-tank of evaluation professionals in Ethiopia, established in 2009 and officially registered in 2010. As a member of AfrEA, EEvA aims to be a Center of Excellence in evaluation competency and standards in Africa</p>
          
  </div>
   
  </div>
  <div className="carousel-item carousel_item">
  <div className=' '>
  <h2 className="display-4 fw-bold w-100 text-center container">
  Ten + Years (2009-2023) <span></span></h2>
          <p className="fw-bolder ">As a professional association, EEVA has been working for more than 10 years to improve the evaluation thinking, practice, and culture in Ethiopia. The major activities done, achievements, and future priorities of EEVA are presented as follows.</p>
          
  </div>       
 
  </div>
  <div className="carousel-item carousel_item">
  <div className=' '>
  <h2 className="display-4 fw-bold w-100 text-center container">More Than 300 Members <span></span></h2>
          <p className="fw-bolder ">
          the association has more than 300 registered M&E practitioners, emerging evalu- ators, and representatives of interested groups from different sectors; government institutions, academia, UN agencies, NGOs, and private consultants

          </p>
          
  </div>  
   
  </div>
</div>

{/* <!-- Left and right controls/icons --> */}
<button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
  <span className="carousel-control-prev-icon"></span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
  <span className="carousel-control-next-icon"></span>
</button>
      </div>
      </div>
      </div>
    
    <div className='container mt-4'>
        <div className='row intro'id='intro'>
            <div className='col-sm-12 bg-light p-4 rounded col-md-6'>
                <h3 className='main_title'> Who we are</h3>
                <p className='intro_paragraph'>
                EEvA, the Ethiopian Evaluation Association, is a voluntary 
                think-tank of evaluation professionals in Ethiopia, established in 2009 and officially registered in 2010. As a member of AfrEA, EEvA aims to be a Center of Excellence in evaluation competency and standards in Africa. With a focus on knowledge generation, awareness creation, capacity building,
                 and advocacy, EEvA provides a platform for professionals
                  to exchange insights.<span>{visible?<p>
                    Upholding principles like intentionality, impartiality, integrity, quality, and transparency,
                     EEvA has achieved significant milestones over its ten-plus years, including organizing conferences,
                      conducting capacity-building trainings, and participating in national evaluation initiatives.
                       Looking forward, EEvA plans to expand its membership, contribute to national evaluation policy
                        development, and engage in high-quality evaluations for socio-economic development. 
                  </p>:<p>...</p>}</span>
                </p>
                <button className='btn btn-primary' onClick={toggle}>{visible?<span>Read Less</span>:<span>Read More</span>}</button>
            </div>
            <div className='col-sm-12 col-md-6 py-4'>



<div id="demo-2" className="carousel slide" data-bs-ride="carousel">

  {/* <!-- Indicators/dots --> */}
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#demo-2" data-bs-slide-to="0" className="active"></button>
    <button type="button" data-bs-target="#demo-2" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo-2" data-bs-slide-to="2"></button>
  </div>
  
  {/* <!-- The slideshow/carousel --> */}
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="../Assets/blog/photo_2024-03-18_13-23-20.jpg" alt="Los Angeles" className="d-block img-fluid rounded " />
      <div className="carousel-caption">
        <h3>Evaluating Youth Leadership</h3>
        <p>We had such a great time  !</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="../Assets/blog/photo_2024-06-29_10-26-55.jpg" alt="Chicago" className="d-block img-fluid rounded" />
      <div className="carousel-caption">
        <h3>Evidence Generation and Governance</h3>
        <p>Mekelle</p>
      </div> 
    </div>
    <div className="carousel-item">
      <img src="../Assets/blog/photo_2024-06-29_10-27-05.jpg" alt="New York" className="d-block img-fluid rounded" />
      <div className="carousel-caption">
        <h3>Mekelle Annual Conference</h3>
        <p>Mekelle!</p>
      </div>  
    </div>
  </div>
  
  {/* <!-- Left and right controls/icons --> */}
  <button className="carousel-control-prev" type="button" data-bs-target="#demo-2" data-bs-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo-2  " data-bs-slide="next">
    <span className="carousel-control-next-icon"></span>
  </button>
</div>

            </div>
            
        </div>
</div>
</div>
  )
}

export default Slide_hero