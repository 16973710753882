import React,{useEffect, useState} from 'react'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Link, useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { get_today_date } from '../../functions/member_functions';
import {Editor} from "primereact/editor"
import api from '../../Apis/api';
function Add_Blog_Modal({modal_status,Modal_toggle,refresh_data}) {
    const navigate= useNavigate();
    const [submitbutton,setStatus]=useState(false);
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [blog_info,setInfo]=useState({
      blog_title:"",
      short_description:"",
      blog_content:"",
      blog_photos:null,
      author_id:account?.account_id,
      timestamp:get_today_date()
      
    });
    const[blog_images,setImages]=useState();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const toggle = () => Modal_toggle();
    
    useEffect(()=>
    {
      if(account==null)
      {
        navigate("/admin/login");
      }
    },[])
    const calculateTotalSize=(files)=> {
      var totalSize = 0;
     files.map((file) => totalSize += file.size)
        return totalSize;
    }
  //for input handel change
  const onHandelChange=(e)=>{
    setDialog(false);
   if(e.target.name=="blog_photos")
   {
   
     const files=e.target.files;
    if(calculateTotalSize(Array.from(files))<=1e+7 ) {
      setStatus(false);
     setInfo( (prev)=>({
      ...prev,
      [e.target.name]:files
    }))
    setImages(Array.from(files));
 }
 else{
  setText("File size to large")
  setType("error");
  setDialog(true);
  setStatus(true)

 }
   }
   else
   {
    setInfo( (prev)=>({
      ...prev,
      [e.target.name]:e.target.value
    }))
   }
  }
  const handel_textarea_change=(e)=>{
    setInfo( (prev)=>({
      ...prev,
      blog_content:e.htmlValue
    }))
  }
  const handel_short_desc_change=(e)=>{
    setInfo( (prev)=>({
      ...prev,
      short_description:e.htmlValue
    }))
  }
  const onHandelSubmit= async(e)=>
  {
    e.preventDefault();
   const formData= new FormData();
   for ( var key in blog_info ) {
     if(key=="blog_photos")
     {
      for (let i = 0; i < blog_info.blog_photos.length; i++) {
        formData.append(key, blog_info.blog_photos[i]);
      }
     }
      else{ 
        formData.append(key, blog_info[key]);}
   }
   try {
     const response=await api.post("/blog/upload",formData,
     {
       headers:{"Content-Type":"multipart/form-data"}
     }
     );
     if( response.data.status=="success")
     {
      setText("Blog uploaded successfully")
      setType("success");
      setDialog(true);
      setTimeout(() => {
        setDialog(false);
        refresh_data()
        setInfo({
          blog_title:"",
          blog_content:"",
          blog_photos:null,
          author_id:account.account_id,
          timestamp:get_today_date()
        })
        setImages(null);
        toggle();
      }, 1500);
     }
     
    
   } catch (error) {
    console.log(error);
    setText(error.response.data.error)
    setType("error");
    setDialog(true);
   }


  }
  return (
    <div className='container-fluid'>
         <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> Add a new Blog <PostAddIcon/></h2>
            </ModalHeader>
            <ModalBody className='admin_color'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-5'>

                    {/* <!-- Carousel --> */}
<div id="demo" className="carousel slide" data-bs-ride="carousel">

  {/* <!-- Indicators/dots --> */}
  <div className="carousel-indicators">
   {
      blog_images && blog_images.map((image,index)=>{
        return(
          <button type="button" data-bs-target="#demo" data-bs-slide-to={index} className={index==0?"active bg-success":"bg-success"}></button>
        )
      })
   } 
  </div>
  
  {/* <!-- The slideshow/carousel --> */}
  <div className="carousel-inner">
    {
      blog_images && blog_images.map((image)=>{
        return(
          <div className="carousel-item active">
      <img src={URL.createObjectURL(image)} alt={image.name} className="d-block rounded w-100" />
    </div>
        )
      })
    }
    
    
  </div>
  
  {/* <!-- Left and right controls/icons --> */}
  <button className="carousel-control-prev " type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span className="carousel-control-prev-icon bg-success p-2 rounded "></span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span className="carousel-control-next-icon bg-success p-2 rounded"></span>
  </button>
</div>
                    </div>
                    
                    <div className='col-sm-12 col-lg-7 bg-white rounded px-5'>
                      <h6 className='text-dark mt-3'> Please create your new Blog by filling the Details Below</h6>
                    <form onSubmit={onHandelSubmit}>
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="form-floating mb-3 mt-3">
                      <input type="Text" className="form-control" value={blog_info.blog_title} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="blog_title"/>
                      <label htmlFor="Text">Blog Title</label>
                     </div>
                     <div className=" mb-3 mt-3">
                     {/* <textarea className="form-control" rows="8" id="blog_content" value={blog_info.blog_content} required onChange={onHandelChange} name="blog_content"></textarea> */}
                     <label htmlFor="Text">Short Description</label>
                     <Editor value={blog_info.short_description} name='content' style={{height:"200px"}} onTextChange={handel_short_desc_change}/>
                     {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <label htmlFor="Text">Full Content</label>
                     <div className=" mb-3 mt-3">
                     {/* <textarea className="form-control" rows="8" id="blog_content" value={blog_info.blog_content} required onChange={onHandelChange} name="blog_content"></textarea> */}
                      
                     <Editor value={blog_info.blog_content} name='content' style={{height:"400px"}} onTextChange={handel_textarea_change}/>
                     {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <div className="form-floating mb-3 mt-3">
                    <input type="file" className="form-control" multiple accept=".jpg, .jpeg, .png" onChange={onHandelChange}  id="profile_pic" placeholder="Upload Blog" name="blog_photos"/>
                    <label for="profile_pic">Blog picture</label>
                      </div>
                     <div className='row p-3'>
                        <button type='submit' disabled={submitbutton}  className='btn btn-success fw-bold' >
                       Post <PostAddIcon/>
                        </button>
                      </div>
                    </form>
                    </div>

                </div>
                </ModalBody>
            </Modal>
    </div>
  )
}

export default Add_Blog_Modal