import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import  "../Css_for_components/Blogs.css";
import Blog from '../components/Blog';
import Alert_Modals from '../components/Alert_Modals';
import moment from 'moment';
import { get_blogs_active,get_blogs_recent, search_ablog } from '../functions/api_call_functions';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Skeleton } from '@mui/material';
function Blogs() {
  const [allBlogs,setBlogs]=useState(null);
  const [recentBlogs,setRecentBlogs]=useState(null);
  const [search_input,setinput]=useState(null);
  const [alert_dialog,setDialog]=useState(false)
  const [skeletonLoading,setSkeleton]=useState(true)
  const[alert_text,setText]=useState("");
  const[alert_type,setType]=useState();
  const [loadingBlogs, setLoadingBlogs] = useState(true);
  const navigate=useNavigate()
  const onhandelSubmit=(e)=>{
    e.preventDefault();
  }
useEffect(()=>{

  get_blogs_active("Active").then((res)=>{

    if(res.status=="success" && res.result>0) {
      
      setBlogs( res.Blog)
      setLoadingBlogs(false);
      }
    })
    get_blogs_recent("Active").then((res)=>{

      if(res.status=="success" && res.result>0) {
        
        setRecentBlogs( res.Blog)
        setSkeleton(false);
      
        }
      }) 
},[]);

const handel_open_blog=(blog_id,blog_title)=>{
    navigate(`/Blogs/${blog_title}/${blog_id}`);
}

const onhandelChange=(e)=>{
  setSkeleton(true);
  setinput(e.target.value);
  search_ablog(e.target.value).then((res)=>{
    console.log(res);
    if(res.status=="success" ){
        if(res.result>0)
        {
          setRecentBlogs( res.Blog);
          setSkeleton(false)
        }
        else{
          setRecentBlogs( null);
          setSkeleton(false)
        }
    }
    else{
      setText("Internal server Error while submitting query  ")
      setType("error");
      setDialog(true);
      setTimeout(() => {
        setDialog(false)
      }, 1500);
    }
  })
 
}
  return (
    <div className='container-fluid p-0'>
       
        <div className="  blog_title_container">
        <div className='container'>
        <h6>Home</h6>
        <h5>Blogs</h5>
        </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-lg-8 p-3'>
                <div className="">
                  {
                  loadingBlogs ? (
                    <div className="loading-container">
                        <CircularProgress className="large-spinner" color="inherit" />
                    </div>
                ) :( allBlogs!==null?
                      allBlogs.map((blog,index)=>{
                       
                        if(blog.status=="Active"){
                          return(
                            <Blog  key={index} blog={blog}/>
                        )
                        }
                      })
                    :( <Alert_Modals type="warning" 
                      text="Sorry no Blogs Posted yet"
                        />))
                  }
                 
                </div>
            </div>
            <div className='col-sm-12 col-lg-4 p-3'>
                <div className='shadow blogs_right_container rounded'>
                   <div> <h5> Search </h5></div>
                   <div>
                     <form onSubmit={onhandelSubmit}>
                     <div className="input-group">
                    <input type="search" className="form-control" value={search_input} name="search_input" onChange={onhandelChange} />
                    <button className="input-group-text search btn"><SearchIcon/></button>
                 </div>
                     </form>

                     {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

                   </div>
                   <div className='mt-4 '>
                    <h5>Recent Posts</h5>
                   </div>

                   {
                     skeletonLoading? 
                     <Skeleton className='MuiSkeleton-fitContent ' variant="rounded" width={350} height={90} animation="wave"  />
                     :(recentBlogs !==null?
                     (<>
                     {
                        recentBlogs.map((blog,index)=>{
                          return(
                            <div key ={index} className='d-flex p-2 shadow rounded' style={{ cursor: "pointer"}} onClick={()=>handel_open_blog(blog.id,blog.title)}>
                        <div>
                            <img src={`https://eeva-server.ethiopianevaluationassociation.org/Blogs/${blog.cover_image}`} className="recent_post_Img" alt=''/>
                        </div>
                        <div className='d-flex flex-column'>
                            <h6>{blog.title}</h6>
                            <p className='text-secondary px-2'>{moment(blog.created_at ).format('YYYY-MM-DD')}</p>
                        </div>
                   </div>
                          )
                        })
                     }</>):(<> <Alert_Modals type="warning" 
                     text="Sorry no Blogs Posted yet"
                       /></>))
                   }
                   
                   
                </div>
            </div>

          </div>
        </div>
          
     
      
       
    </div>
  )
}

export default Blogs
