import React, { useState } from 'react'
import "../Css_for_components/signup_page.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../Apis/api';
import { username_validation } from '../Admin/Functions/admin_functions';
import Alert_Modals from '../components/Alert_Modals';
import { set_member } from '../Store/Action';
import { Home, LinkedIn } from '@mui/icons-material';
import { checkPasswordStrength, get_today_date, validateEthiopianPhoneNumber } from '../functions/member_functions';
import { get_member_account } from '../functions/api_call_functions';


function Signup_page() {
  const [disable,setDisable]=useState(false);
  const [user_info,setInfo]=useState({
      firstname:"",
      lastname:"",
      gender:null,
      title:null,
      email:"",
      username:"",
      password:"",
      phonenumber:"",
      role:"Member",
      status:"Active",
      end_date:get_today_date(),
      created_date:get_today_date()

  })
  const navigate=useNavigate();
  const dispatch= useDispatch();
  const [alert_dialog,setDialog]=useState(false);
  const [password_alert_dialog,setAlert]=useState(false);
  const [psw_text,setPsw_text]=useState("");
  const[alert_text,setText]=useState("");
  const[alert_type,setType]=useState();
  const [pwsvisisble,SetVisisble]=useState(false);
  //for handiling password visiblity
  const togglePassword=()=>{
    SetVisisble(!pwsvisisble);
  }
  // for handling submit
  const handel_submit=async(e)=>{
    e.preventDefault();
    if(user_info.gender==null || user_info.title==null)
    {
      setText("Please Select Gender and Title also ")
      setType("error");
      setDialog(true);
    }
   else{
    setDialog(false);
     const value= validateEthiopianPhoneNumber(user_info.phonenumber);
  if (value.isValid)
   {
     try {
      const response=await api.post("/register/memeber",{
        user_info
      })
      if(response.data.status=="success")
      {
        
        setText("Account created ")
        setType("success");
        setDialog(true);
        setTimeout(() => {
          navigate("/Loginpage")
        }, 1000);
       
      }
      else
      {
        setText("Sorry Internal server error ")
        setType("error");
        setDialog(true);
      }
    } catch (error) {
      console.log(error);
      setText(error.response.data.error)
      setType("error");
      setDialog(true);
      
    }
  }
  else
  {
    setDialog(true);
            setText(value.message)
            setType("error")
            setTimeout(() => {
                setDialog(false);
              }, 3000);
  }
   }
  }
  //for handiling change in the input fileds
  const handel_change=(e)=>{
    setDialog(false
      );
     if(e.target.name=="username") {
      username_validation(e.target.value).then((res)=>{
          if(res)
          {
              setText("Username is already in use please choose another")
              setType("error");
              setDialog(true);
              setDisable(true);
          }
          else
          {
              setDisable(false);
          }
      })
      setInfo( (prev)=>({
          ...prev,
          [e.target.name]:e.target.value
        }))
  }
  else if (e.target.name=="password")
    {
  const result = checkPasswordStrength(e.target.value);
        if( !result.status)
        {
          setAlert(true);
          setPsw_text(result.message);
          setType("error");
          setDisable(true);
        }
        else{
          setAlert(true);
          setPsw_text(result.message);
          setType("success");
          setDisable(false);
        }


    }
      setInfo((pre)=>
      ( 
        {...pre,
        [e.target.name]:e.target.value
        }
      )
    )
   

  };
  return (
    <div className="container p-2 ">
       <Link className='btn btn-success' to="/"><Home/></Link>
        <div className='row'>
            <div className='col-sm-12 col-lg-7 p-3'>
                <div className='bg-light rounded shadow p-3'>
                  <div className='d-flex justify-content-center'>
                  <img src='../Assets/EEVA_logo.png' alt='Logo' className=' login_page_logo img-fluid rounded'/>
                  </div>
                <h3 className=' register_title'>Register and Become a Member</h3>
                    <form onSubmit={handel_submit} >
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="row">
    <div className="col-sm-12 col-lg-6">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={handel_change} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
        <label for="firstname">First Name</label>
        </div>    
    </div>
    <div className="col-sm-12 col-lg-6">
    <div className="form-floating mb-3 mt-3">
  <input type="text" className="form-control" onChange={handel_change} required value={user_info.lastname}id="lastname" placeholder="enter last name" name="lastname"/>
  <label for="lastname">Last Name</label>
</div>
    </div>
  </div>
             
  <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={handel_change} required value={user_info.gender}name='gender'>
             <option value="">please select Gender</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
          </select>
          <label for="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={handel_change} required value={user_info.title} name='title'>
                <option value="">---please select Title---</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
            </select>
            <label for="title">Title</label>
      </div>
    </div>
  </div>
      <div className="form-floating mb-3 mt-3">
        <input type="username" className="form-control" value={user_info.username}onChange={handel_change} required id="username" placeholder="Enter username" name="username"/>
        <label for="Username">Username</label>
    </div>

    <div className='row'>
    <div className='col-sm-12'>
    <div className="input-group form-floating mt-3 mb-3 ">
          <input type={pwsvisisble?"text":"password"} className="form-control" value={user_info.password} onChange={handel_change} required id="pwd" placeholder="Enter password" name="password"/>
          <label for="pwd">Password</label>
          <span className="input-group-text" onClick={togglePassword}><VisibilityIcon/></span>
        </div>
        {password_alert_dialog && <h6 className={alert_type=="success"?" p-1 text-success":"p-1 text-danger"}>{psw_text}</h6>}

    </div>
    </div>

      <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control"  value={user_info.email} onChange={handel_change} required id="email" placeholder="Enter email" name="email"/>
      <label for="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className='input-group mt-3 mb-3'>
          <span class="input-group-text fw-bold">+251</span>
        <div className="form-floating  ">
                  <input type="text" className="form-control" value={user_info.phonenumber} onChange={handel_change} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
      <label for="phonenumber">Phonenumber</label>
    </div>
    </div>
    </div>
</div>
    <div className="d-flex justify-content-center mt-3 mb-3">
      <button type='submit' className='btn btn-success w-100 fw-bolder mx-auto' disabled={disable}>Submit</button>
     </div>
     <div className="d-flex justify-content-center mt-3 mb-3">
     <Link to="/Loginpage"> <h6 className='backto_login_link'>Already Have an Account ?</h6></Link>
     </div>
 </form>
                </div>
            </div>
            <div className='d-none d-lg-block col-sm-12 col-lg-5 p-5'>
              <div className='benefits_container shadow rounded'>
                  <h6 > Benefits of Becoming a Member</h6>
                   <div><KeyboardArrowRightIcon/><h6>Update on the latest news</h6></div>
                   <div><KeyboardArrowRightIcon/><h6>Update on the latest news</h6></div>
                   <div><KeyboardArrowRightIcon/><h6>Update on the latest news</h6></div>
                   <div><KeyboardArrowRightIcon/><h6>Update on the latest news</h6></div>
                   <div><KeyboardArrowRightIcon/><h6>Update on the latest news</h6></div>
              </div>
            </div>
        </div>

    </div>
  )
}

export default Signup_page