import React from 'react'
import "../Css_for_components/aboutus.css"
import { ArrowRight } from '@mui/icons-material'
function About_us_page() {
  return (
<div className='container-fluid p-0'>
        <div className='row'>
            <div className='col-sm-12 p-5 about_us_page_title_container'>
                <div className='d-flex'>
                    <h5>Home</h5><ArrowRight/><h5 className='text-secondary'>About Us</h5>
                </div>
                <div className='mt-3'>
                    <h2>About Us</h2>
                </div>
            </div>
        </div>
        <div className='container my-lg-5'>
           <div className='row my-5 py-sm-3 py-5'>
           <div className='col-sm-12 col-lg-6'>
                <img src="/Assets/blog/about-one.jpg" alt='about-us image' className='img-fluid my-auto'/>
            </div>
            <div className='col-sm-12 col-lg-6'>
                <div className='aboutuspage_content_container'>
                    <h6> About us</h6>
                    <h3>Ethiopian Evaluation Association.</h3>
                    <p>
                    EEvA, the Ethiopian Evaluation Association, is a voluntary think-tank of evaluation professionals in Ethiopia, established in 2009 and officially registered in 2010. As a member of AfrEA, EEvA aims to be a Center of Excellence in evaluation competency and standards in Africa. With a focus on knowledge generation, awareness creation, capacity building, and advocacy, EEvA provides a platform for professionals to exchange insights. Upholding principles like intentionality, impartiality, integrity, quality, and transparency, EEvA has achieved significant milestones over its ten-plus years, including organizing conferences, conducting capacity-building trainings, and participating in national evaluation initiatives. Looking forward, EEvA plans to expand its membership, contribute to national evaluation policy development, and engage in high-quality evaluations for socio-economic development. Contact details for further information are provided for those interested in joining or learning more about EEvA.
                    </p>
                </div>
            </div>

           </div>
           <div className='row my-5 py-lg-5'>
           
            <div className='col-sm-12 col-lg-6'>
                <div className='aboutuspage_content_container'>
                    <h6> About us</h6>
                    <h3>We Promise</h3>
                    <p>
                    At the Ethiopian Evaluation Association (EEvA), our commitment is to uphold the highest standards of evaluation excellence in Ethiopia and beyond. We promise to: 
                    </p>
                    <p>
                   <span className='fw-bolder'>1.  Champion Knowledge Generation:</span> We are dedicated to continuously generating and sharing valuable insights through robust evaluations and research.
                   </p>
                   <p>
                   <span className='fw-bolder'>2.  Foster Awareness:</span> By creating awareness about the importance of evaluation, we strive to enhance understanding and appreciation of its role in socio-economic development.
                   </p>
                   <p>
                   <span className='fw-bolder'>3.  Build Capacity:</span> Our initiatives include comprehensive training programs designed to equip evaluation professionals with the skills and knowledge they need to excel in their field.
                   </p>
                   <p>
                   <span className='fw-bolder'>4.  Advocate for Excellence:</span> We advocate for policies and practices that promote intentionality, impartiality, integrity, quality, and transparency in all evaluation activities.
                   </p>
                   <p>
                   <span className='fw-bolder'>5.  Facilitate Professional Exchange: </span>Through our conferences and events, we provide a platform for professionals to exchange ideas, experiences, and best practices, fostering a community of learning and growth.
                   </p>
                   <p>
                   <span className='fw-bolder'>6.  Contribute to Policy Development:</span>We actively participate in shaping national evaluation policies, ensuring that our expertise contributes to the development and implementation of effective evaluation frameworks..
                   </p>
                </div>
            </div>
            <div className='col-sm-12 col-lg-6'>
              <div className='d-flex align-center'>
              <img src="/Assets/blog/about-two.jpg" alt='about-us image' className='img-fluid'/>
              </div>
            </div>

           </div>
        </div>


    </div>
  )
}

export default About_us_page