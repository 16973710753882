import React, { useState } from 'react';
import  "../Css_for_components/Loginpage.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import api from '../Apis/api';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert_Modals from './Alert_Modals';
import Cross_Check_request from './Cross_Check_request';
import { set_member } from '../Store/Action';
import Forgotpassword from './Forgotpassword.js'
import { get_member_account } from '../functions/api_call_functions';
import Password_change_modal from './Password_change_modal';
function LoginPage() {
    const[user_info,SetInfo]=useState({
        
        username:"",
        password:""
    })
    
    const navigate=useNavigate();
    const dispatch= useDispatch();
    const[account,setAccount]=useState();
    const[modal_request,setRequesStatus]=useState(false);
    const [modal_status,setStatus]=useState(false);
    const [pws_change,setPws_change]=useState(false);
    const Modal_toggle=()=>
    {
      setStatus(!modal_status);
    }
    const pws_toogle=()=>{
        setPws_change(!pws_change);
    }
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const[showpassword,setShow]=useState(false);
    const toogle_request=()=>{
        setRequesStatus(!modal_request);
    }
    const toogle_password=()=>{
        setShow(!showpassword);
    }
    const onHandelchange=(e)=>{
            SetInfo( (prev)=>({
                ...prev,
                [e.target.name]:e.target.value
              }));
    }
    const onHandelSubmit=async(e)=>{
        e.preventDefault();
        try {
            const response= await api.post("/Login/member",{withCredentials: true,user_info})
            if(response.data.status=="success")
            {   
                if(response.data.result.status=="Active")
                {
                  localStorage.setItem("Token",JSON.stringify(response.data.token));
                    get_member_account(response.data.result.account_id,response.data.token).then((res)=>{
                        if(res.status==="success")
                        { 
                            if(res.result==0)
                            {
                                setText("As a Admin you can not access this site please use your Member Detail instade")
                                setType("error");
                                setDialog(true);
                                setTimeout(() => {
                                    setDialog(false);
                                  }, 2000);
                            }
                          else { 
                            if(res.user.membership_status=="Active"){
                              dispatch(set_member(res.user));
                              localStorage.setItem("Member_account",JSON.stringify(res.user))
                                if(res.user.activation=="Active")
                                {
                                 
                                 
                                  setText("Login successful ")
                                  setType("success");
                                  setDialog(true);
                                  setTimeout(() => {
                                    navigate("/")
                                  }, 1500);
                                }
                                else
                                {
                                  setAccount(res.user);
                                  pws_toogle();
                                }
                            }
                            else{
                                setAccount(res.user);
                                setTimeout(() => {
                                   toogle_request();
                                  }, 1000);


                            }
                        
                        }
                        }
                        else{
                          setText("Error occured while try to fetch you account please try loging in from login page ")
                          setType("error");
                          setDialog(true);
                          setTimeout(() => {
                            setDialog(false);
                          }, 2000);
                          
                        }
                      }) 
                       
                }
                else{
                    setText("Please contact you system Admin your account is not active ")
                    setType("error");
                    setDialog(true);
                    setTimeout(() => {
                        setDialog(false);
                      }, 2000);
                }
            }
            else
            {
                setText(response.data.msg)
                setType("error");
                setDialog(true);
                setTimeout(() => {
                    setDialog(false);
                  }, 2000);
            }
            
        } catch (error) {
        console.log(error);
      setText(error.response.data.msg)
      setType("error");
      setDialog(true);
        }
    }
  return (
    <div className="container-fluid bg-light  p-0">
        <div className='row'>

            {/* left side */}
            <div className='col-lg-7 d-none d-lg-block lg_page_left_container'>
            <img src='../Assets/loginpage_icon.png' alt='Logo' className='mt-5  img-fluid rounded'/>
            </div>

            {/* right side */}
            <div className=' col-sm-12 col-lg-5 h-100 p-sm-5  p-lg-4 h-100    '>
                <div className=' p-sm-0 py-lg-3 lg_page_right_container shadow px-lg-5'>

                
                <div className='container  rounded '>
                    <div className='row'>
                        <div className='col mb-2 d-flex justify-content-center'>
                        <Link className='' to="/"> <img src='../Assets/EEVA_logo.png' alt='Logo' style={{cursor:"pointer"}} className=' login_page_logo img-fluid rounded'/></Link>
                        </div>
                    </div>
                    <div className='row  welcome_text'>
                    <h4 className=' w-100  text-center '>
                        Welcome Back !!
                    </h4>
                    <h6 className='text-center text-light w-100'>Please Enter your Details</h6>
               
                    </div>
                    </div>

                <form className='Login_form py-1 px-5 ' onSubmit={onHandelSubmit} >
                {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                <div className="mb-4 mt-3">
                    <label  className="form-label">Username</label>
                    <input type="username" required className="form-control login_inputs" id="Username" onChange={onHandelchange} value={user_info.username} placeholder="" name="username"/>
                </div>
                <div className="mb-4">
                    <label  className="form-label">Password</label>
                    <div className="input-group">
                        <input type={showpassword?"text":"password"} required className="form-control login_inputs" onChange={onHandelchange} value={user_info.password}  id="pwd" placeholder="" name="password"/>
                        <span className="input-group-text password_icon_container  p-0" onClick={toogle_password}><VisibilityIcon  className='password_icon'/></span>
                     </div>
                </div>
                <div className=' d-lg-flex justify-content-between p-1 '>
                <div className="form-check mb-3">
                    <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" name="remember"/> Remember me
                    </label>
                    </div>
                       <div className='mb-3'>
                       <span className='forgot_password' onClick={Modal_toggle}>Forgot password ?</span>
                       </div>
                   
                    
                    
                </div>
                <button type="submit" className="btn btn-success fw-bolder w-100">Submit</button>
                </form>
                <h6 className=' mt-1 signup_link text-center w-100'>New Here <Link to="/Signup_page"><span className='signup_links'>Create an Account</span></Link></h6>

                </div>
            </div>

        </div>
        <Password_change_modal modal_status={pws_change} Modal_toggle={pws_toogle} account={account}/>
        <Cross_Check_request modal_status={modal_request} Modal_toggle={toogle_request} account={account}/>
        <Forgotpassword modal_status={modal_status} Modal_toggle={Modal_toggle}/>
    </div>
  )
}

export default LoginPage