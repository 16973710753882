import React,{useEffect, useState} from 'react'
import  "../Css_for_components/Announcments.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function Announcements_Page({Announcement, modal_status,Modal_toggle}) {
  const toggle=()=>{
    Modal_toggle()
  }
  return (
    <div className='container-fluid p-0'>
             <Modal centered="true" className=' rounded' isOpen={modal_status} size="lg" toggle={toggle} >
        <ModalHeader  className='announcement_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> </h2>
            </ModalHeader>
            <ModalBody className=' announcement_color  py-0 '>
              <div className='row '>
                <div className='col-sm-6  col-lg-6 p-0'>
                  <div className=''>
                  <img src={`https://eeva-server.ethiopianevaluationassociation.org/Announcements/${Announcement.picture}`} alt={Announcement.picture} className='img-fluid rounded' style={{maxHeight:"550px",objectFit:"fill"}} />

                  </div>
                </div>
                <div className='col-sm-6 col-lg-6'>
                    <div className='announcement_container'>
                        <h3>{Announcement.title} </h3>
                        <div dangerouslySetInnerHTML={{__html:Announcement.content}}></div>
                    </div>
                </div>
              </div>
            </ModalBody>
            </Modal>
    </div>
  )
}
