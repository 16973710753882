import React from 'react'
import  "../Css_for_components/homepage.css";
import Slide_hero from '../components/Slide_hero'
import Conduct from '../components/Conduct'
import { conduct } from '../DummyData/dummyData'
import Pricing from '../components/Pricing';
import Objectives_section from '../components/Objectives_section';
function Home_page() {
  return (
    <div className='container-fluid  '>
        
     
        <Slide_hero />
        <Conduct dummy_data={conduct}/>
        <Objectives_section/>
        <Pricing/>
       
        
      
    </div>
  )
}

export default Home_page