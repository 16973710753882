import React, { useEffect, useState } from 'react'
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, Collapse, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_today_date } from '../../functions/member_functions';
import {Editor} from "primereact/editor"
import api from '../../Apis/api';
import Catagory_ViewTable from './Catagory_ViewTable';


function Add_material_modal({modal_status,Modal_toggle,refresh_data}) {
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [submitbutton,setStatus]=useState(false);
    const [material_info,setInfo]=useState({
        title:"",
        Description:"",
        material_file:null,
        upload_by:account?.account_id,
        upload_date:get_today_date(),
        catagory_id:""
        
      });
      const [alert_dialog,setDialog]=useState(false)
      const[alert_text,setText]=useState("");
      const[alert_type,setType]=useState();
      const[catagory_info,setCatInfo]=useState({
          catagory_name:""
      })
      const [catagorys,setCatagory]=useState(null)
      const [isOpen, setIsOpen] = useState(false);
   
      const get_catgory=async()=>{
        try {
          const response= await api.get("/Material/get-all/catagory");
          if( response.data.status=="success")
          {
              setCatagory(response.data.catagorys)
          }
            
        } catch (error) {
            console.log(error)
              setText("Internal server error while loading catagory")
              setType("error");
              setDialog(true);
        }
     

    }
     const handl_collapse=()=>{
        setIsOpen(!isOpen)
      }
      const refresh=()=>{
          refresh_data();
          get_catgory();
      }
    
 
      const toggle = () => Modal_toggle();

      //handel change
      const onHandelChange=(e)=>{
        setStatus(false)
        if(e.target.name=="material_file")
        {
          const files=e.target.files[0];
          console.log(files.size);
          if(files.size<=1e+7){
            setDialog(false);
          setInfo( (prev)=>({
           ...prev,
           [e.target.name]:files
         }))
        }
        else{
          setText("File size to large")
          setType("error");
          setDialog(true);
          setStatus(true)
        }
        }
       
        else
        {
         setInfo( (prev)=>({
           ...prev,
           [e.target.name]:e.target.value
         }))
        }
      }
      const handel_textarea_change=(e)=>{
        setInfo( (prev)=>({
          ...prev,
          Description:e.htmlValue
        }))
      }
      //handel submit
      const onHandelSubmit=async(e)=>
      {
        e.preventDefault();
        const formData= new FormData();
        for ( var key in material_info ) {  
            formData.append(key, material_info[key]);
       }
       try {

        const response=await api.post("/Material/upload",formData,
        {
          headers:{"Content-Type":"multipart/form-data"}
        }
        );
        if( response.data.status=="success")
        {
         setText("Material uploaded successfully")
         setType("success");
         setDialog(true);
         setTimeout(() => {
           setDialog(false);
           refresh_data()
           setInfo({
            title:"",
        Description:"",
        material_file:null,
        catagory_id:""
           })
           toggle();
         }, 1500);
        }
           
       } catch (error) {
        console.log(error);
        setText(error.response.data.error)
        setType("error");
        setDialog(true);
       }

       
      }
      const handeSubmitCatagory=async(e)=>{
        e.preventDefault();
        try {
            const response=await api.post("/Catagory/create-new/",{
                catagory_info
            })
            if(response.data.status=="success"){
                setText("Catagory created Successfuly");
                setType("Success");
                setDialog(true);
                setTimeout(() => {
                    setDialog(false);
                    refresh()
                    setCatInfo({
                    catagory_name:"",
                    })
                  }, 1500);
                
            }
            
        } catch (error) {
            console.log(error)
                setText("Internal server error Creating new Catagory");
                setType("error");
                setDialog(true);
        }
      }
      useEffect(()=>{
        get_catgory();
      },
      []
      )
    return (
    <div className='container-fluid'>
         <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> Add a new Material<PostAddIcon/></h2>
            </ModalHeader>
            <ModalBody className='admin_color'>
                <div className='row p-0'>
                {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

                <div className='col-sm-12 col-lg-6 mx-lg-3 bg-white rounded py-3 px-5'>
                      <h6 className='text-dark mt-3'> Please Upload your new Material by filling the Details Below</h6>
                    <form onSubmit={onHandelSubmit}>

                    <select className="form-control" onChange={onHandelChange} required value={material_info. catagory_id}name='catagory_id'>
                        <option value="">please select Catagory</option>
                            {
                                catagorys && catagorys.map((cat)=>{
                                    return(
                                        <option value={cat.catagory_id}>{cat.catagory_name}</option>
                                    )
                                })
                            }
                        </select>

                    <div className="form-floating mb-3 mt-3">
                      <input type="Text" className="form-control" value={material_info.title} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="title"/>
                      <label htmlFor="Text">Material Title</label>
                     </div>
                     <div className=" mb-3 mt-3">
                     {/* <textarea className="form-control" rows="8" id="blog_content" value={material_info.blog_content} required onChange={onHandelChange} name="blog_content"></textarea> */}
                      
                     <Editor value={material_info. Description} name='content' style={{height:"300px"}} onTextChange={handel_textarea_change}/>
                     {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <div className="form-floating mb-3 mt-3">
                    <input type="file" className="form-control" accept=".pdf" onChange={onHandelChange}  id="profile_pic" placeholder="" name="material_file"/>
                    <label for="profile_pic">file</label>
                      </div>
                     
                     <div className='row p-3'>
                        <button type='submit'  disabled={submitbutton} className='btn btn-success fw-bold' >
                       Post <PostAddIcon/>
                        </button>
                      </div>
                    </form>
                    </div>
             
                <div className='col-sm-12 col-lg-5 bg-white rounded py-3 px-5'>
                <h6 className='w-100 text-dark text-center my-lg-3'> Please click Add catagory to create a new catagory</h6>

                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-primary'onClick={handl_collapse}>Add catagory <AddIcon/></button>
                    </div>
                    <Collapse isOpen={isOpen}>
                    <div className='row'>
                        <form onSubmit={handeSubmitCatagory}>
                        <div className="form-floating mb-3 mt-3">
                      <input type="Text" required className="form-control" value={catagory_info.catagory_name} onChange={(e)=>setCatInfo({catagory_name:e.target.value})} required id="Title" placeholder="Enter Title" name="catagory_name"/>
                      <label htmlFor="Text">catagory name</label>
                     </div>
                     <div className='row p-3'>
                        <button type='submit'   className='btn btn-success fw-bold' >
                       Add <AddIcon/>
                        </button>
                      </div>
                        </form>
                    <div className='container-fluid mt-4'>
                        <div className='col-sm-12'>
                            <Catagory_ViewTable result={catagorys} refresh_data={refresh}/>
                        </div>
                    </div>
                    </div>
                    </Collapse>
                  

                </div>
                </div>
            
               

            </ModalBody>
            </Modal>
    </div>
  )
}

export default Add_material_modal