import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { get_today_date } from '../../functions/member_functions';
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { get_all_members, username_validation } from '../Functions/admin_functions';
import api from '../../Apis/api';
import { checkPasswordStrength } from '../../functions/member_functions';
function Add_user_modal({modal_status,Modal_toggle}) {
    const [listOfMembers,setList]=useState(null)
    const [disable,setDisable]=useState(false);
    const [password_alert_dialog,setAlert]=useState(false);
    const [psw_text,setPsw_text]=useState("");
    const [user_info,setInfo]=useState({
        firstname:"",
        lastname:"",
        selected_member:"",
        gender:"",
        email:"",
        phonenumber:"",
        role:"",
        username:"",
        password:"",
        status:"Active",
        start_date:get_today_date()
  
    })
    const set_user_data=(account_id)=>{
        const [member]= listOfMembers.filter((member)=>member.account_id==account_id)
      
        setInfo( (prev)=>({
            ...prev,
            firstname:member.firstname,
            lastname:member.lastname,
            gender:member.gender,
            email:member.email,
            phonenumber:member.phonenumber,
          }))
    }
    const navigate= useNavigate();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const [pwsvisisble,SetVisisble]=useState(false);
    const toggle = () => Modal_toggle();
     //for handiling password visiblity
  const togglePassword=()=>{
    SetVisisble(!pwsvisisble);
  }
    //handel change of input fields
    const onHandelChange=(e)=>{
        setDialog(false);
        if(e.target.name=="selected_member")
        {
            set_user_data(e.target.value);
            setInfo( (prev)=>({
                ...prev,
                [e.target.name]:e.target.value
              }))
        }
        else if(e.target.name=="username") {
            username_validation(e.target.value).then((res)=>{
                if(res)
                {
                    setText("Username is already in use please choose another")
                    setType("error");
                    setDialog(true);
                    setDisable(true);
                }
                else
                {
                    setDisable(false);
                }
            })
            setInfo( (prev)=>({
                ...prev,
                [e.target.name]:e.target.value
              }))
        }
        else if (e.target.name=="password")
          {
        const result = checkPasswordStrength(e.target.value);
              if( !result.status)
              {
                setAlert(true);
                setPsw_text(result.message);
                setType("error");
                setDisable(true);
              }
              else{
                setAlert(true);
                setPsw_text(result.message);
                setType("success");
                setDisable(false);
                
              }
      
      
          }
        
        
            setInfo( (prev)=>({
                ...prev,
                [e.target.name]:e.target.value
              }))
        
      
        }
        //use Effect for loading existing members
        useEffect(()=>{
            get_all_members().then((res)=>{
                setList(res)
            })
           
        },[])
        //for handling the form
        const onHandelSubmit=async(e)=>
        {
            e.preventDefault();
           try {
               
            const response=await api.post("/register/user",{
                user_info
              })
              if(response.data.status=="success")
              {
                setText("Account created ")
                setType("success");
                setDialog(true);
                setTimeout(() => {
                    setInfo({        
                    firstname:"",
                    lastname:"",
                    selected_member:"",
                    gender:null,
                    email:"",
                    phonenumber:"",
                    role:"",
                    username:"",
                    password:"",
                    status:"Active",
                    start_date:get_today_date()
              
                })
                toggle();
                }, 1000);
              }
              else
              {
                setText("Sorry Internal server error ")
        setType("error");
        setDialog(true);
              }
           } catch (error) {
            console.log(error);
            setText(error.response.data.error)
            setType("error");
            setDialog(true);
           }
        }
  return (
    <div className='container-fluid' >
       
        <Modal  className='admin_color rounded' isOpen={modal_status} size="xl" toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center '> Create new User</h2>
            </ModalHeader>
            <ModalBody className='admin_color'>
            <div className='row'>
            <div className='col-sm-12 col-lg-8 mx-auto'>
            <form onSubmit={onHandelSubmit}>
            {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

            <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange}  value={user_info.title} name='selected_member'>
                <option value="">---Select form Members---</option>
               {
                   listOfMembers && listOfMembers.map((member)=>{
                       return(
                        <option key={member.account_id} value={member.account_id}>{member.firstname} {member.lastname}</option>
                       )
                   })
               }
            </select>
            <label htmlFor="title">Select form Members</label>
      </div>
                <div className="row">
    <div className="col-sm-12 col-lg-6">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
        <label htmlFor="firstname">First Name</label>
        </div>    
    </div>
    <div className="col-sm-12 col-lg-6">
    <div className="form-floating mb-3 mt-3">
  <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.lastname}id="lastname" placeholder="enter last name" name="lastname"/>
  <label htmlFor="lastname">Last Name</label>
</div>
    </div>
  </div>

  <div className="form-floating mb-3 mt-3">
        <input type="username" className="form-control" value={user_info.username} onChange={onHandelChange} required id="username" placeholder="Enter username" name="username"/>
        <label htmlFor="Username">Username</label>
    </div>
    <div className='row'>
      <div className='col-sm-12'>
      <div className="input-group form-floating mt-3 mb-3 ">
          <input type={pwsvisisble?"text":"password"} className="form-control" value={user_info.password} onChange={onHandelChange} required id="pwd" placeholder="Enter password" name="password"/>
          <label htmlFor="pwd">Password</label>
          <span className="input-group-text" onClick={togglePassword}><VisibilityIcon/></span>
        </div>
        {password_alert_dialog && <h6 className={alert_type=="success"?" p-1 text-success":"p-1 text-danger"}>{psw_text}</h6>}

      </div>
    </div>
 
    <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={onHandelChange} required value={user_info.gender}name='gender'>
             <option value="">please select Gender</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
          </select>
          <label htmlFor="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info.role} name='role'>
                <option value="">---please select role---</option>
                <option value="Admin">Admin</option>
                <option value="Content creator">Content creator</option>
             
            </select>
            <label htmlFor="title">Role</label>
      </div>
    </div>
  </div> 

   
  <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control"  value={user_info.email} onChange={onHandelChange} required id="email" placeholder="Enter email" name="email"/>
      <label htmlFor="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control" value={user_info.phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
      <label htmlFor="phonenumber">Phonenumber</label>
    </div>
    </div>
</div>
       <div className='row p-3'>
           <button type='submit' disabled={disable}  className='btn btn-success fw-bold' >
                       Submit
            </button>
        </div>
                </form>
            </div>
        </div>
            </ModalBody>
            <ModalFooter className='admin_color'>

<Button color="secondary" onClick={toggle}>
Done
</Button>

</ModalFooter>

        </Modal>
    </div>
  )
}

export default Add_user_modal