import React, { useEffect, useState } from 'react'
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import { useNavigate } from 'react-router-dom';
import "../css/TitleHeader.css";
import { useDispatch } from 'react-redux';
import { reset_user_state,set_user_state } from '../../Store/Action';
import { logout } from '../../functions/member_functions';

function TitleHeader({title,icon}) {
  const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
  const navigate= useNavigate();
  const dispatch= useDispatch();
  useEffect(()=>{
    if(account==null)
    {
      navigate("/Admin/login");
    }
    else{
      dispatch(set_user_state(account));
    }
  },[])
  const getlogout=()=>{
  
    localStorage.removeItem("Admin_user_account");
    setAccount(null);
    dispatch(reset_user_state());
    logout(false);
  }
  return (
    <div className='container title_container p-2 '>
    <div>
    <h4 className='header_title'>
    {icon}   {title}
    </h4>
    </div>
    
    <div>
    <h6>Welcome Back !</h6> 
          <h5>{account?.username} <SettingsPowerIcon className='sign_out'onClick={getlogout}/></h5>
    </div>
</div>
  )
}

export default TitleHeader