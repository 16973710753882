import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import Alert_Modals from '../../components/Alert_Modals';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import TitleHeader from '../components/TitleHeader';
import api from '../../Apis/api';
function Edit_User_page() {
    const navigate= useNavigate();
    const selected_account= useSelector((state)=>state.admin_reducer.selected_user)||null;
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const [pwsvisisble,SetVisisble]=useState(false);
    const [user_info,setInfo]=useState({
        firstname:"",
        lastname:"",
        gender:"",
        email:"",
        phonenumber:"",
        role:"",
        username:"",
        password:"",
        status:"",
 
  
    })
    useEffect(()=>{
        if(selected_account==null)
        {
            navigate("/Admin/dashbord");
        }
        else
        {
            setInfo({
                firstname:selected_account.firstname,
        lastname:selected_account.lastname,
        gender:selected_account.gender,
        email:selected_account.email,
        phonenumber:selected_account.phonenumber,
        role:selected_account.role,
        username:selected_account.username,
        status:selected_account.status,
        account_id:selected_account.account_id
            })

        }

    },[])
    const onHandelChange=(e)=>{
        setDialog(false);
                  setInfo( (prev)=>({
                ...prev,
                [e.target.name]:e.target.value
              }))
       
      
        }
        const onhandelSubmit=async(e)=>{
            e.preventDefault();
            try {
                const response=await api.put("/user/profile/update",{
                    user_info
                })
                console.log(response);
                if(response.data.status=="success")
                {
                  setText("Profile Updated successfully")
                  setType("success");
                  setDialog(true);
                  setTimeout(() => {
                   navigate("/Admin/Manage-Users");
                  }, 3000);
                }
                
                
            } catch (error) {
                console.log(error);
                setText(error.response.data.error)
                setType("error");
                setDialog(true);
            }
        }
  return (
    <div className='container-fluid'>
          <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<PersonAddAltIcon/>} title="Edit User Info"/>
      </div>
            <div className='container'>
        <div className='row'>
            <div className='col-sm-12 col-lg-8 mx-auto mt-3'>
                <form onSubmit={onhandelSubmit}>
                {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                <div className="row">
    <div className="col-sm-12 col-lg-6">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
        <label htmlFor="firstname">First Name</label>
        </div>    
    </div>
    <div className="col-sm-12 col-lg-6">
    <div className="form-floating mb-3 mt-3">
  <input type="text" className="form-control" onChange={onHandelChange} required value={user_info.lastname}id="lastname" placeholder="enter last name" name="lastname"/>
  <label htmlFor="lastname">Last Name</label>
</div>
    </div>
  </div>

  <div className="form-floating mb-3 mt-3">
        <input type="username" className="form-control" disabled="true" value={user_info.username} onChange={onHandelChange} required id="username" placeholder="Enter username" name="username"/>
        <label htmlFor="Username">Username</label>
    </div>
  
    <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={onHandelChange} required value={user_info.gender}name='gender'>
             <option value="">please select Gender</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
          </select>
          <label htmlFor="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info.role} name='role'>
                <option value="">---please select role---</option>
                <option value="Admin">Admin</option>
                <option value="Content creator">Content creator</option>
             
            </select>
            <label htmlFor="title">Role</label>
      </div>
    </div>
  </div> 

   
  <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control"  value={user_info.email} onChange={onHandelChange} required id="email" placeholder="Enter email" name="email"/>
      <label htmlFor="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control" value={user_info.phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
      <label htmlFor="phonenumber">Phonenumber</label>
    </div>
    </div>
</div>
<div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={user_info.status} name='status'>
                <option value="">---please select Status---</option>
                <option value="Active">Active</option>
                <option value="inactive">inactive</option>
             
            </select>
            <label htmlFor="title">Status</label>
      </div>
       <div className='row p-3'>
           <button type='submit'   className='btn btn-success fw-bold' >
                       Update
            </button>
        </div>
                
                </form>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Edit_User_page