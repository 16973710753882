import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import moment from "moment";
import { Edit } from '@mui/icons-material';
import Alert_Modals from '../../components/Alert_Modals';
import api from '../../Apis/api';
import { set_blog_selected } from '../../Store/Action';
function Blogs_ViewTable({result,refresh_data}) {
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const handel_edit=async(blog_id)=>
    {
        const [blog]=result.filter((data)=>data.id==blog_id);
        dispatch(set_blog_selected(blog));
        navigate(`/Admin/Blog/Edit/${blog.id}`);
        
    }
    const handel_Deactivation=async(blog_id)=>
    {
        try {
        
            const response=await api.put(`/Blogs/Deactivate/${blog_id}`)
            if(response.data.status=="success")
            {
                setDialog(true);
                setType("success")
                setText("Blog has been successfuly Deactivated")
                setTimeout(() => {
                    setDialog(false);
                    refresh_data()
                  }, 1500);
            }
            
        } catch (error) {
            console.log(error);
            setDialog(true);
            setType("error");
            setText("Internal Server Error occured while trying Deactivate the Blog")
            setTimeout(() => {
             setDialog(false);
             refresh_data()
           }, 1500);
        }

    }
    const hadel_delete=async(blog_id)=>
    {
       
          if( window.confirm("Are you sure you want to remove this Blog?"))
           {try {
            const response= await api.delete(`/Blogs/Delete/${blog_id}`)
            if(response.data.status=="success")
            {
                setDialog(true);
                setType("success")
                setText("Blog has been successfuly removed")
                setTimeout(() => {
                    setDialog(false);
                    refresh_data()
                  }, 1500);
            }
           } catch (error) {
               console.log(error);
               setDialog(true);
               setType("error");
               setText("Internal Server Error occured while trying to remove the Blog")
               setTimeout(() => {
                setDialog(false);
                refresh_data()
              }, 1500);
            }}
    }

    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const columns=[
        {label:"Blog ID",name:"id" ,options: { filter: false,}},
        {label:"Blog title",name:"title" ,options: { filter: false,}},
        {label:"Author",name:"username"},
        {label:"Content",name:"content" ,options: { filter: false,}},
        {label:"Created Date",name:"created_at",
        options:{
            filter: false,
            customBodyRender:(value)=>(
                moment(value ).format('YYYY-MM-DD')
            ) }
    },
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
                if(value=="Active")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="inactive")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"Action",name:"id", options:{
            filter: false,
            customBodyRender:(value)=>(
               
                   <>
                    <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_edit(value)}>Edit<Edit  className=' btn-success '/></span>
                    <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                    <span className='btn btn-danger mx-1 my-1'onClick ={()=>hadel_delete(value)}><DeleteIcon/></span>
                   </>
               
            )
    }},
      ];
  if(  result!== undefined && result !== null )  { 
  return (
      <>
{alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

    <MUIDataTable 
    title={"All Blogs"}
    data={result}
    columns={columns}
    options={options}
    className="text-color"
  /></>
  )}
  else
  {
    return (
    <Alert_Modals type="error" 
    text="Sorry no Announcments found on the system !"
      />
      )
    }
}

export default Blogs_ViewTable