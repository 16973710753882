import React, { useEffect,useState } from 'react'
import TitleHeader from '../components/TitleHeader'
import { Link, useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import CampaignIcon from '@mui/icons-material/Campaign';
import RefreshIcon from '@mui/icons-material/Refresh';
import Add_announcement_modal from '../components/Add_announcement_modal';
import Announcement_viewTable from '../components/Announcement_viewTable';
import SimpleBackdrop from '../components/SimpleBackdrop';
import { get_all_announcements } from '../Functions/admin_functions';

function Announcemnet_management() 
{
    const [Addannouncement, setModal ] = useState(false);
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [Allannouncemnet,setAnnouncemnt]=useState(null);
    const navigate=useNavigate();
    const refresh_data=()=>
    {
        setAnnouncemnt(null);
        get_data();
    }
    const get_data=()=>
        {
            get_all_announcements().then((res)=>{
                if(res.status=="success")
                {
                    setAnnouncemnt(res.announcement);
                }
            })
        }
    const toogleModal=()=>
        {
          setModal(!Addannouncement);
      }
      useEffect(() => {
        if(account==null)
        {
          navigate("/Admin/login");
        }
        else{
            get_data();
        }
      }, [])

  return (
    <div className='container-fluid'>
      <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<CampaignIcon/>} title="Announcement Management"/>
      </div>
      <div className='container-fluid admin-color'>
      <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                <button className='btn btn-primary fw-bold mx-2' onClick={toogleModal} >Add Announcement <CampaignIcon/></button>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
        {Allannouncemnet ? <Announcement_viewTable result={Allannouncemnet} refresh_data={refresh_data} className="text-dark"/> : <SimpleBackdrop/>}
        </div>
        </div>
      </div>
      <Add_announcement_modal modal_status={Addannouncement} Modal_toggle={toogleModal} refresh_data={refresh_data}/>

    </div>
  )
}

export default Announcemnet_management