import * as ac_types from "./Actiontypes"
export const initialState=
{
   member:null
   
 
};
export const memeber_reducer =(state=initialState,action)=>{
    switch(action.type){
        case ac_types.Set_member:
            {
                console.log(action.payload);
            return{
                ...state,
                member:action.payload
            }
        }
        case ac_types.rest_member:
            return{
                ...state,
                member:null
            }
        default:
            return state;
    }
}