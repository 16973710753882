import React, { useEffect, useState } from 'react'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TitleHeader from '../components/TitleHeader';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import Add_material_modal from '../components/Add_material_modal';
import Material_ViewTable from '../components/Material_ViewTable';
import SimpleBackdrop from '../components/SimpleBackdrop';
import { get_all_materials } from '../Functions/admin_functions';
function Material_managment() {
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [addMaterialModal,setMaterialModal]= useState();
    const [allMaterial,setMaterial]=useState(null);
    const navigate= useNavigate();
    const fetch_data=()=>{
      get_all_materials().then((res)=>{
        if(res.status=="success")
       { 
        setMaterial(res.material);
      }
      })
    }
    useEffect(()=>{
        if (account==null)
        {
            navigate("/admin/login");
        }
        else{
         fetch_data()
        }

    },[])

    //refresh data
    const refresh_data=()=>{
        setMaterial(null);
        fetch_data();
    }
    const toogleModal=()=>
    {
        setMaterialModal(!addMaterialModal);
    }
    
  return (
    <div className='container-fluid'>
       <div className='container-fluid whole_title_container shadow '>
    <TitleHeader icon={<DriveFolderUploadIcon/>} title="Material Managment"/>
    </div>
    <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                <button className='btn btn-primary fw-bold mx-2' onClick={toogleModal} >Upload material <DriveFolderUploadIcon/></button>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
          {allMaterial ? <Material_ViewTable result={allMaterial} refresh_data={refresh_data} className="text-dark"/>:<SimpleBackdrop/>}
        </div>

      </div>
          <Add_material_modal  modal_status={addMaterialModal} Modal_toggle={toogleModal} refresh_data={refresh_data}/>
    </div>
  )
}

export default Material_managment