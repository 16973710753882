import * as ac_types from "./Actiontypes.js"
//FOR SETTING MEMBER TO THE STATE WHEN LONGIN IN OR SIGNING UP
 export const set_member=(Member_data)=>{
     return {
         type:ac_types.Set_member,
         payload:Member_data
    }
    
 }
//  FOR RESETTING MEMBER NULL ON THE STATE WHEN  MEMBER lOGS OUT
 export const reset_member=()=>{
    return{
        type:ac_types.rest_member
    }
 }








//  AMDIN ACTIONS////////////////////////////////////////////////////////////////////////
// SETTING USER DATA WHEN LOGIN IN FROM ADMIN
export const set_user_state=(user_data)=>
{
    return{
        type:ac_types.set_user,
        payload:user_data
    }
}
//FOR RESETING USER WHEN LOGIN OUT AS ADMIN
export const reset_user_state=()=>{
    return{
        type:ac_types.rest_user
    }
 }

 export const set_selected_user=(user_data)=>{
    return{
        type:ac_types.selected_user,
        payload:user_data
    }
 }

 //for selecting a blog to edit the info
 export const set_blog_selected=(blog_data)=>{
     return{
         type:ac_types.selected_blog_edit,
         payload:blog_data
     }
 }
 
 //FOR selecting a member to generate an id 
 export const set_selected_member=(member_data)=>{
    return{
        type:ac_types.selected_member,
        payload:member_data
    }
 }