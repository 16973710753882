import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Alert_Modals from './Alert_Modals';

function Transaction_member_view({result,refresh_data}) {
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const columns=[
        {label:"Transaction ID",name:"transaction_id", options: { filter: false,}},
        {label:"Transaction reference ",name:"gateway_transaction_id", options: { filter: false,}},
        {label:"Reason",name:"description"},
        {label:"Amount",name:"amount", options: { filter: false,}},
        {label:"Currency",name:"currency", options: { filter: false,}},
        {label:"Date",name:"timestamp", options:{filter: false,
            customBodyRender:(value)=>(
                moment(value ).format('YYYY-MM-DD')
            ) }
    },
        {label:"Status",name:"status", options:{
            customBodyRender:(value)=>{
                if(value=="Success")
                    { 
                    return (
                        <span className='badge bg-success'> {value}</span>
                    )
                    }
                    else if(value=="Declined")
                    {
                        return (
                            <span className='badge bg-danger'> {value}</span>
                        )
                    }
                    else if(value=="Pending")
                        {
                            return (
                                <span className='badge bg-primary'> {value}</span>
                            )
                        }
             } }
    },
        
    ]
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      if(  result!== undefined && result !== null )  { 
        return (
            <>
      {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
      
          <MUIDataTable 
          title={"All Your Transactions"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        /></>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no Transactions  found on the system !"
            />
            )
          }
      }


export default Transaction_member_view