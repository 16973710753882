import React,{useEffect, useState} from 'react'
import CampaignIcon from '@mui/icons-material/Campaign';
import { Link, useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import api from '../../Apis/api';
import moment from "moment";
import { get_today_date } from '../../functions/member_functions';

import {Editor} from "primereact/editor"

function Add_announcement_modal({modal_status,Modal_toggle,refresh_data}) {
    const date= new Date();
    const navigate= useNavigate();
    const [submitbutton,setStatus]=useState(false);
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [Announcement_info,setInfo]=useState({
        title:"",
       content:"",
        photo:null,
        author_id:account.account_id,
        post_date:moment(date).format('YYYY-MM-DD'),
        end_date:"",
        status:"Active",
        
      });
    const[Announcement_images,setImages]=useState();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    useEffect(()=>
        {
          if(account==null)
          {
            navigate("/Admin/login");
          }
        },[]);
        const toggle = () => Modal_toggle();
        const handel_textarea_change=(e)=>{
          setInfo( (prev)=>({
            ...prev,
            content:e.htmlValue
          }))
        }
        const onHandelChange=(e)=>{
          setDialog(false);
            if(e.target.name=="photo")
            {
              const files=e.target.files[0];
              if (files.size<=2097152)
              {
                setStatus(false)
                setInfo( (prev)=>({
               ...prev,
               [e.target.name]:files
             }))
             setImages(files);
          }else{
            setText("File size to large")
            setType("error");
            setDialog(true);
            setStatus(true)
          }
            }
           
            else
            {
             setInfo( (prev)=>({
               ...prev,
               [e.target.name]:e.target.value
             }))
            }
           }
           const onHandelSubmit= async(e)=>
            {
              e.preventDefault();
              console.log(Announcement_info);
              const formData= new FormData();
              for ( var key in Announcement_info ) {
                
              
                   formData.append(key, Announcement_info[key]);
              }
              try {
                const response=await api.post("/Announcement/upload",formData,
                {
                  headers:{"Content-Type":"multipart/form-data"}
                }
                );
                if( response.data.status=="success")
                    {
                     setText("Announcement uploaded successfully")
                     setType("success");
                     setDialog(true);
                     setTimeout(() => {
                       setDialog(false);
                       refresh_data()
                       setInfo({
                        title:"",
                        content:"",
                            photo:null,
                            author_id:account.account_id,
                            post_date:moment(date).format('YYYY-MM-DD'),
                            end_date:"",
                            status:"Active"
                       })
                       setImages(null);
                       toggle();
                     }, 1500);
                    }
                
              } catch (error) {
                console.log(error);
                setText(error.response.data.error)
                setType("error");
                setDialog(true);
              }
            }
  return (
    <div className='container-fluid'>
         <Modal fullscreen={true} className='admin_color  rounded' isOpen={modal_status}  toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> Add a new Announcement <CampaignIcon/></h2>
            </ModalHeader>
            <ModalBody className='admin_color'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-5'>

                    <div className=' '>
                    {Announcement_images&&<img src={URL.createObjectURL(Announcement_images)} alt={Announcement_images.name} className="d-block border border-primary  rounded-circle mx-auto img-fluid" style={{maxHeight:"800px"}} />}

                    </div>

                    </div>
                    
                    <div className='col-sm-12 col-lg-7 bg-white rounded px-5'>
                      <h6 className='text-dark mt-3 fw-bolder'> Please create your Announcement by filling the Details Below</h6>
                    <form onSubmit={onHandelSubmit}>
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="form-floating mb-3 mt-3">
                      <input type="Text" className="form-control" value={Announcement_info.title} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="title"/>
                      <label htmlFor="Text">Announcement Title</label>
                     </div>
                     <div className=" mb-3 mt-3">
                     {/* <textarea className="form-control" rows="8" id="blog_content" value={Announcement_info.content} required onChange={onHandelChange} name="content"></textarea> */}
                      <Editor value={Announcement_info.content} name='content' style={{height:"400px"}} onTextChange={handel_textarea_change}/>
                      {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <div className="form-floating mb-3 mt-3">
                      <input type="date" className="form-control" min={  moment(get_today_date() ).format('YYYY-MM-DD')} value={Announcement_info.post_date} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="post_date"/>
                      <label htmlFor="Text">Start Date</label>
                     </div>
                     <div className="form-floating mb-3 mt-3">
                      <input type="date" className="form-control" min={moment(get_today_date() ).format('YYYY-MM-DD')} value={Announcement_info.end_date} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="end_date"/>
                      <label htmlFor="Text">End Date </label>
                     </div>
                     <div className="form-floating mb-3 mt-3">
                    <input type="file" className="form-control" required  accept=".jpg, .jpeg, .png" onChange={onHandelChange}  id="profile_pic" placeholder="Upload Photo" name="photo"/>
                    <label for="profile_pic">Announcement picture</label>
                      </div>
                     <div className='row p-3'>
                        <button type='submit' disabled={submitbutton}   className='btn btn-success fw-bold' >
                       Post <CampaignIcon/>
                        </button>
                      </div>
                    </form>
                    </div>

                </div>
                <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
                </ModalBody>
            </Modal>
    </div>
  )
}

export default Add_announcement_modal