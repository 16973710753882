
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import DiamondIcon from '@mui/icons-material/Diamond';
export const conduct=[
    {
        title:"Mission",
        content:["EEVA's mission is to encourage and enable evaluation professionals to contribute to the country's socio-economic development through: Knowledge generation Awareness creation Capacity buildingAdvocacy, and rendering model service in evaluation."],
        Icon:<TrackChangesIcon/>
    },
    {
        title:"Vision",
        content:["EEVA aspires to be the Center of Excellence in evaluation competency and standards in Africa."],
        Icon:<RemoveRedEyeIcon/>
    },
    {
        title:"Values",
        content:["Intentionality","Impartiality","Integrity","Quality","Transparancy"],
        Icon:<DiamondIcon/>
    }
]