import React,{useState,useEffect} from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TitleHeader from '../components/TitleHeader';
import SimpleBackdrop from"../components/SimpleBackdrop";
import RefreshIcon from '@mui/icons-material/Refresh';
import Add_Blog_Modal from '../components/Add_Blog_Modal';
import { get_all_Blogs } from '../Functions/admin_functions';
import { useNavigate } from 'react-router-dom';
import Blogs_ViewTable from '../components/Blogs_ViewTable';

function Blogs_management() {
    const [AddBlogModal, setModal ] = useState(false);
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [AllBlogs,setBlogs]=useState(null);
    const navigate=useNavigate();
    const get_allblogs=()=>{
          get_all_Blogs().then((res)=>{
       if(res.status=="success") {
        setBlogs( res.Blog)
        }
          })
    }
    const refresh_data=()=>{
        setBlogs(null);
        get_allblogs();
    }
    const toogleModal=()=>
  {
    setModal(!AddBlogModal);
}
useEffect(() => {
  if(account==null)
  {
    navigate("/Admin/login");
  }
  else{
    get_allblogs();
  }
}, [])

  return (
    <div className='container-fluid'>
             <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<PostAddIcon/>} title="Blog Management"/>
      </div>
      <div className='container-fluid admin_container_color'>
          <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                <button className='btn btn-primary fw-bold mx-2' onClick={toogleModal} >Add Blog <PostAddIcon/></button>

                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
        {AllBlogs ? <Blogs_ViewTable result={AllBlogs} refresh_data={refresh_data} className="text-dark"/> : <SimpleBackdrop/>}
        </div>
        </div>
        </div>
        <Add_Blog_Modal modal_status={AddBlogModal} Modal_toggle={toogleModal} refresh_data={refresh_data}/>
        
    </div>
  )
}

export default Blogs_management