import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";
import api from '../Apis/api';
import { useNavigate } from 'react-router-dom';
import { WindowSharp } from '@mui/icons-material';
import Alert_Modals from './Alert_Modals';
import { validateEthiopianPhoneNumber } from '../functions/member_functions';
import { error } from 'jquery';
function Payment_Modal({ modal_status,Modal_toggle ,account,payment_reason_data}) {
        const [payment_Details,setDetails]=useState({
            firstname:account?.firstname,
            lastname:account?.lastname,
            username:account?.username,
            account_id:account?.account_id,
            phonenumber:account?.phonenumber,
            reason:payment_reason_data?.reason,
            amount:payment_reason_data?.amount,
        })
    const [alert_dialog,setDialog]=useState(false);
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
      
    const toggle=()=>{
        Modal_toggle();

    }
    const handel_change=(e)=>{
        setDetails( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
    }
    //for processing payment
    const handel_payment=async(e)=>{
        e.preventDefault();
        const value =validateEthiopianPhoneNumber(payment_Details.phonenumber);
       if (value.isValid){ try {
            const response= await api.post(`/payment/checkout/`,{
                payment_Details
            })
            window.location.href=response.data.data.paymentUrl;

        } catch (error) {
            console.log(error)
        }}
        else
        {
            setDialog(true);
            setText(value.message)
            setType("error")
            setTimeout(() => {
                setDialog(false);
              }, 4000);
        }

    }
    useEffect(()=>{
        setDetails({
            firstname:account?.firstname,
            lastname:account?.lastname,
            username:account?.username,
            account_id:account?.account_id,
            phonenumber:account?.phonenumber,
            reason:payment_reason_data?.reason,
            amount:payment_reason_data?.amount,
        })
    },[
        account,payment_reason_data
    ])
  return (
   <div className='container-fluid'>
            <Modal  className='  rounded' isOpen={modal_status}  toggle={toggle} style={{backgroundColor:"#3165a8"}}>
        <ModalHeader  className='text-center w-100 ' toggle={toggle} style={{backgroundColor:"#3165a8"}}>
           <div className=' w-100'>
           <h2 className='w-100  text-center fw-bold  text-white'> Payment</h2>
           </div>
            </ModalHeader>
            <ModalBody className='bg-white p-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 '>
                            <div className='d-flex justify-content-center p-3' style={{alignItems:"center"}}>
                                <h1 className='mx-1 fw-bold text-success display-1'>{payment_Details.amount}</h1>
                                <h4 className='mx-1 pt-2 fw-bold text-secondary mt-4'>Birr</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handel_payment}>
                            
              {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
            
                        <div className='col-sm-12'>
                            <div className='row'>
                            <div className="col-sm-12 col-lg-6 max-auto">
            <div className="form-floating  mt-3">
        <input type="text" className="form-control" value={payment_Details.firstname} disabled={true} id="firstname"  onChange={handel_change} placeholder="Enter first name" name="firstname"/>
        <label for="firstname">First Name</label>
        </div>    
    </div>
                                        <div className="col-sm-12 col-lg-6 mx-auto">
                                        <div className="form-floating  mt-3">
                                    <input type="text" className="form-control" value={payment_Details.lastname} onChange={handel_change} disabled={true} id="lastname" placeholder="enter last name" name="lastname"/>
                                    <label for="lastname">Last Name</label>
                                    </div>
                                        </div>
                        </div>
                    <div className='row mt-1'>
                                         
                                            
                                    <div className="col-sm-12 col-lg-12">
                                    <div className='input-group mt-3 mb-3'>
          <span class="input-group-text fw-bold">+251</span>
        <div className="form-floating  ">
        <input type="text" className="form-control"  value={payment_Details.phonenumber} onChange={handel_change} id="Phonenumber" placeholder="enter last name" name="phonenumber"/>
        <label for="phonenumber">Phonenumber</label>
    </div>
    </div>

                                    </div>
                                    <div className="col-sm-12 col-lg-12">
                                    <div className="form-floating  mt-3">
                                        <select className="form-control" disabled={true} value={payment_Details.reason} onChange={handel_change} required name='reason'>
                                            <option value="">Reason</option>
                                            <option value="membership">Membership Renewal</option>
                                            <option value="donation">Donation</option>
                                            <option value="ID">Digital ID payment</option>
                                        </select>
                                        <label for="Gender">Reason</label>
                                        </div>   
                                    </div>
                                   { payment_Details.reason!=="membership"?
                                   (<div className="col-sm-12 col-lg-12">
                                        <div className="form-floating  mt-3">
                                        <input type="number" className="form-control" disabled={payment_Details.reason=="donation"?false:true} min={5} value={payment_Details.amount} onChange={handel_change}  id="Phonenumber" placeholder="enter last name" name="amount"/>
                                        <label for="lastname">Amount</label>
                                        </div>
                                    </div>):
                                    (<div className="col-sm-12 col-lg-12">
                                    <div className="form-floating  mt-3">
                                        <select className="form-control"  value={payment_Details.amount} onChange={handel_change} required name='amount'>
                                            <option value="5">500 Birr for One Year Membership</option>
                                            <option value="6">1000 Birr for One Two Year Membership</option>
                                            <option value="7">3000 Birr for One Life Time </option>
                                           
                                        </select>
                                        <label for="Gender">Membership Fee</label>
                                        </div>   
                                    </div>)}
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="form-floating w-100 mt-3">
                                        <button className='btn btn-success w-100 fw-bold' >Process</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        </form>
                   
                    </div>
                                        
                </div>
        
                </ModalBody>
                <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
            </Modal>
   </div>
  )
}

export default Payment_Modal