import React ,{useEffect, useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get_today_date } from '../functions/member_functions';
import Alert_Modals from './Alert_Modals';
import api from '../Apis/api';
import { check_existing_request } from '../functions/api_call_functions';
import Payment_Modal from './Payment_Modal';
function Cross_Check_request({modal_status,Modal_toggle,account})
 {
  const toggle = () => Modal_toggle();
  const navigate= useNavigate();
  const [disable,setDisable]=useState(false);
  const [alert_dialog,setDialog]=useState(false)
  const[alert_text,setText]=useState("");
  const[alert_type,setType]=useState();
  const [payment_Modal,set_Payment_Modal]=useState(null);
  const Payment_Modal_toggle=()=>{
    set_Payment_Modal(!payment_Modal);
  }
  const handel_cross_check=async()=>{
      const response= await api.post("/Request/register/",{
        account,
        created_date:get_today_date()
      })
    
      if(response.data.status=="success")
      {
        setText("Request Successfully submited you will recive an e-mail with the result of your request soon");
        setType("success");
        setDialog(true);
        disable(true);
      }
      else{
        setText("Sorry there was an Error while submitting reques");
        setType("error");
        setDialog(true);
      }

  }
  useEffect(()=>{
    console.log(account);
      check_existing_request(account?.account_id).then((res)=>{
        if(res.result>=1)
        {
          setDisable(true);
        }
      })
  },[account])
  return (
    <div className="container-fluid">
      <Modal isOpen={modal_status} size="xl" toggle={toggle} >
      <ModalHeader  className='modal_color text-center w-100 ' toggle={toggle}><p className='w-100 text-center'> Membership Status</p></ModalHeader>
      <ModalBody className='modal_color'>
        <h5>Your Membership Status is currently <span className='badge bg-warning'> {account?.membership_status}</span></h5>
        <ul>
        <li>If you`re a new Member that just  registered  Please click on the Renew Button to pay your membership fee</li>
         <li>If you`re Membership Has expired Please click on the Renew Button</li>
         <li>If you Had recently Made payment for Membership But your account is still pending Please click on the Request Cross Check Button </li>
       </ul>
         <div className='container'>
         {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

          <div className='row'>
            <div className='col-sm-12 col-lg-6 p-1'>
                <div className='h-100 rounded shadow d-flex flex-column justify-content-center p-4'>
                    <h6 className='text-dark fw-bold '>If the status of Membership is New/Expired</h6>
                    <button className='btn btn-primary my-3'onClick={Payment_Modal_toggle}>Renew Membership</button>
                </div>
            </div>
            <div className='col-sm-12 col-lg-6 p-1'>
              <div className='h-100 rounded shadow d-flex flex-column justify-content-center p-4'>
              {disable?(<h6 className='text-dark fw-bold '>You Have already Made a request Please wait for that to be processed </h6>):(<h6 className='text-dark fw-bold '>If the status of Membership pending but you have recently paid your Membership fee</h6>)}                    
                <button className='btn btn-success my-3'disabled={disable} onClick={handel_cross_check} >Request Cross-Check </button>
                </div>
            </div>
          </div>
         </div>
         <Payment_Modal modal_status={payment_Modal} Modal_toggle={Payment_Modal_toggle} account={account} payment_reason_data={{reason:"membership",amount:500}} />

      </ModalBody>
      <ModalFooter className='modal_color'>
            
          <Button color="secondary" onClick={toggle}>
          Done
          </Button>

          </ModalFooter>
      </Modal>
   
    </div>
  )
}

export default Cross_Check_request