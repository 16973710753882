import React from 'react'
import { Route ,Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Home_page from './Pages/Home_page';
import Blogs from './Pages/Blogs';
import Navigation_bar from './components/Navigation_bar';
import HomePage_footer from './components/HomePage_footer';
import Contact_us_page from './Pages/Contact_us_page';
import Signup_page from './Pages/Signup_page';
import Edit_profile_page from './Pages/Edit_profile_page';
import Test from './Pages/Test';
import AdminNavbar from './Admin/components/AdminNavbar';
import Admin_Loginpage from './Admin/Pages/Admin_Loginpage';
import User_managment from './Admin/Pages/User_managment';
import Edit_User_page from './Admin/Pages/Edit_User_page';
import Member_managment from './Admin/Pages/Member_managment';
import Blogs_management from './Admin/Pages/Blogs_management';
import Edit_blog_page from './Admin/Pages/Edit_blog_page';
import Announcemnet_management from './Admin/Pages/Announcemnet_management';
import About_us_page from './Pages/About_us_page';
import Configuration_managment from './Admin/Pages/Configuration_managment'
import Material_managment from './Admin/Pages/Material_managment';
import Materials_page from './Pages/Materials_page';
import Blogs_Detailed_page from './Pages/Blogs_Detailed_page';
import Id_card from './Admin/components/Id_card';
import MemberSetting from './Admin/components/MemberSetting';
import Members from './Pages/Members';
import Member_detail_page from './Pages/Member_detail_page';
import Dashbord from './Admin/Pages/Dashbord';
import Request_page from './Admin/Pages/Request_page';
import Messages_page from './Admin/Pages/Messages_page';
import Transactions_page from './Pages/Transactions_page';
import Transaction_management from './Admin/Pages/Transaction_management';
import ID_request_managment from './Admin/Pages/ID_request_managment';

function App() {
  return (
    <div className="container-fluid   p-0">
     
      <Routes>
      <Route path="/" exact   element={<> <Navigation_bar/><Home_page/> 
        <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
       <HomePage_footer/></>}/>
        <Route path="/Loginpage" exact  element={<LoginPage/>}/>
        <Route path="/test" exact  element={<Test/>}/>
        <Route path="/Signup_page" exact  element={<Signup_page/>}/>
        <Route path="/Profile/:username" element={<><Edit_profile_page/></>}/>
        <Route path="/Blogs" element={<> <Navigation_bar/><Blogs/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/Contactus" element={<> <Navigation_bar/><Contact_us_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/About" element={<> <Navigation_bar/><About_us_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/Materials" element={<> <Navigation_bar/><Materials_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/Blogs/:title/:blog_id" element={<> <Navigation_bar/><Blogs_Detailed_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/Members" element={<> <Navigation_bar/><Members/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>

        <Route path="/Members/Profile-view/:username" element={<> <Navigation_bar/><Member_detail_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        <Route path="/Transaction/view/:username" element={<> <Navigation_bar/><Transactions_page/>
          <div className='to_top_arrow text-dark'>
          <div>
          <a href='#topbar'> <ArrowUpwardIcon className='' /></a>

          </div>
        </div>
        <HomePage_footer/></>}/>
        {/*  admin routes  */}
        <Route path="/Admin/Login" exact  element={<Admin_Loginpage/>}/>
        <Route path="/Admin/Dashbord" exact  element={<AdminNavbar ><Dashbord/></AdminNavbar>}/>
        <Route path="/Admin/Manage-Users" exact  element={<AdminNavbar ><User_managment/></AdminNavbar>}/>
        <Route path="/Admin/Edit_Users/:account_id" exact  element={<AdminNavbar ><Edit_User_page/></AdminNavbar>}/>
        <Route path="/Admin/Manage-memebership" exact  element={<AdminNavbar ><Member_managment/></AdminNavbar>}/>
        <Route path="/Admin/Blog-Management" exact  element={<AdminNavbar ><Blogs_management/></AdminNavbar>}/>
        <Route path="/Admin/Blog/Edit/:blog_id" exact element={<AdminNavbar ><Edit_blog_page/></AdminNavbar>}/>
        <Route path="/Admin/Announcement-Management" exact element={<AdminNavbar ><Announcemnet_management/></AdminNavbar>}/>
        <Route path="/Admin/Material-Managment" exact element={<AdminNavbar ><Material_managment/></AdminNavbar>}/>
        <Route path="/Admin/Configuration" exact element={<AdminNavbar ><Configuration_managment/></AdminNavbar>}/>
        <Route path="/Admin/Id/:username" exact element={<AdminNavbar ><Id_card/></AdminNavbar>}/>
        <Route path="/Admin/Member/role-config" exact  element={<AdminNavbar ><MemberSetting/></AdminNavbar>}/>
        <Route path="/Admin/Request-cross-check" exact  element={<AdminNavbar ><Request_page/></AdminNavbar>}/>
        <Route path="/Admin/Messages" exact  element={<AdminNavbar ><Messages_page/></AdminNavbar>}/>
        <Route path="/Admin/Transaction" exact  element={<AdminNavbar ><Transaction_management/></AdminNavbar>}/>
        <Route path="/Admin/ID-requests" exact  element={<AdminNavbar ><ID_request_managment/></AdminNavbar>}/>


      </Routes>
    
       
    </div>
  );
}

export default App;
