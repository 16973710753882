import * as ac_types from "./Actiontypes"
export const initialState=
{
   user_account:null,
   selected_user:null,
   selected_blog:null,
   selected_member:null
    
   
 
};
export const Admin_reducer =(state=initialState,action)=>{
    switch(action.type){
        //setting user on login in
        case ac_types.set_user:
            {
               
            return{
                ...state,
                user_account:action.payload
            }
        }
        // setting selected user on edit
        case ac_types.selected_user:
            {
               
            return{
                ...state,
                selected_user:action.payload
            }
        }
        case ac_types.selected_blog_edit:
            {
                return{
                    ...state,
                    selected_blog:action.payload
                }
            }
            

            //for selecting a member 
        case ac_types.selected_member:
            {
                return{
                    ...state,
                    selected_member:action.payload
                }
            }

        //resting user on logout
        case ac_types.rest_user:
            return{
                ...state,
                user_account:null,
                selected_user:null,
                selected_blog:null,
                selected_member:null
            }
        default:
            return state;
    }
}