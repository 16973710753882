import React,{useEffect, useState} from 'react'
import  "../Css_for_components/Blogs.css";
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import moment from 'moment';
import Alert_Modals from '../components/Alert_Modals';
import { get_ablog, get_blogs_active } from '../functions/api_call_functions';
import { useParams } from 'react-router-dom';
import { get_blog_images } from '../Admin/Functions/admin_functions';
import {FacebookShareButton,TelegramShareButton,TwitterShareButton, WhatsappShareButton,LinkedinShareButton} from "react-share";
import {FacebookIcon,TelegramIcon ,LinkedinIcon,TwitterIcon,WhatsappIcon}  from "react-share";
function Blogs_Detailed_page() {
    const [blog,SetBlog]=useState(null);
    const Url_details=useParams();
    const [images,setImages]=useState();
   const current_url= window.location.href;
  

    useEffect(()=>{
        get_ablog(Url_details.blog_id).then((res)=>{
           
            if(res.status=="success")
            {
                SetBlog(res.Blog);
            }
        });
        get_blog_images(Url_details.blog_id).then((res)=>{
            if(res.status=="success")
            {
              setImages(res.images);
      
            }
          }
        )
        
    },[])
  return (
    <div className='container-fluid'>
        <div className="  blog_title_container">
        <div className='container'>
        <h6>Blog</h6>
        <h5>{Url_details.title}</h5>
        </div>
        </div>
        <div className='row'>
            <div className='col-sm-12 col-lg-9 mx-auto p-3'>
            <div className="shadow">
                  {
                    blog!==null?
                     
                         (  <div className='single_blog_container shadow rounded'>
                            <div id="demo" className="carousel slide" data-bs-ride="carousel">
                
                {/* <!-- Indicators/dots --> */}
                <div className="carousel-indicators">
                {
                images && images.map((image,index)=>{
                return(
                <button key={index} type="button" data-bs-target="#demo" data-bs-slide-to={index} className={index==0?"active bg-success":"bg-success"}></button>
                )
                })
                } 
                </div>
                
                {/* <!-- The slideshow/carousel --> */}
                <div className="carousel-inner">
                {
                images && images.map((image,index)=>{
                  
                return(
                <div className={index==0?"carousel-item active":"carousel-item "} key={image.img_id}>
                <img src={`https://eeva-server.ethiopianevaluationassociation.org/Blogs/${image.image_path}`} alt={image.img_id} className='d-block w-100 h-75 img-fluid rounded' style={{maxHeight:"600px"}}/>  </div>
                )
                })
                }
                
                
                </div>
                
                {/* <!-- Left and right controls/icons --> */}
                <button className="carousel-control-prev " type="button" data-bs-target="#demo" data-bs-slide="prev">
                <span className="carousel-control-prev-icon bg-success p-2 rounded "></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <span className="carousel-control-next-icon bg-success p-2 rounded"></span>
                </button>
                </div>
                        
                        <h4>
                        {blog.title}
                        </h4>
                        <div className='blog_info_container'>
                            <div className='blog_details'>
                                <div>
                                <PersonIcon className="blog_details_icons"/>
                                <h6>{blog.username}</h6>
                                </div>
                                <div>
                                <AccessTimeSharpIcon className="blog_details_icons"/>
                                <h6>
                                {moment(blog.created_at ).format('YYYY-MM-DD')}
                                </h6>
                                </div>
                            </div>
                            <div className='blog_details_content text-secondary'  dangerouslySetInnerHTML={{__html:blog.content}}>
                          
                              
                            </div>
                            <div className='d-flex justify-content-end my-3' style={{gap:"10px"}}>
                                  <FacebookShareButton url={current_url}>
                                    <FacebookIcon size={30} round={true}/>
                                  </FacebookShareButton>
                                  <TelegramShareButton url={current_url} title={blog.title} >
                                    <TelegramIcon size={30} round={true}/>
                                  </TelegramShareButton>
                                  <LinkedinShareButton url={current_url} title={blog.title} summary={blog.short_desc}>
                                   <LinkedinIcon size={30} round={true}/>
                                  </LinkedinShareButton>
                                  <TwitterShareButton url={current_url}  title={blog.title} >
                                    <TwitterIcon size={30} round={true}/>
                                  </TwitterShareButton>
                                  <WhatsappShareButton url={current_url} title={blog.title}>
                                    <WhatsappIcon size={30} round={true}/>
                                  </WhatsappShareButton>
                                 
                              </div>
                         
                            
                        </div>
                    </div>)
                   
                    :( <Alert_Modals type="error" 
                      text="Sorry Error while loading blog"
                        />)
                  }
                 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blogs_Detailed_page