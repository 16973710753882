import React,{useEffect, useState} from 'react'
import Alert_Modals from '../../components/Alert_Modals';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';


function Message_view_modal({modal_status,Modal_toggle,refresh_data,message_data}) {
    const toggle=()=>{
        Modal_toggle();
        refresh_data();
    }
    useEffect(()=>{
        console.log(message_data);
    },[message_data])
  return (
    <div className='container-fluid'>
         <Modal  className='admin_color  rounded' isOpen={modal_status}  toggle={toggle} >
        <ModalHeader  className='admin_color text-center w-100 ' toggle={toggle}>
            <h2 className='w-100 text-center fw-bold '> Message <MarkChatReadIcon/></h2>
            </ModalHeader>
            <ModalBody className='admin_color p-3'>
              <div className='row'>
                    <div className='col-sm-10 mx-auto p-3 bg-white shadow rounded'>
                    <div className='col-sm-12 mx-auto  p-1'>
                    <div className='d-flex justify-content-center bg-white p-1'>
                        <img src="/Assets/blank-profile-picture.webp" className="img-fluid rounded-circle border border-primary" style={{height:"150px",objectFit:"contain"}} alt=""/>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className='d-flex flex-column mt-3 p-2 rounded '>
                    
                    <div className='message_modal_container' style={{gap:"10px"}}>
                    <h6 className='text-secondary'>Subject:</h6>
                       <h6 className='text-dark fw-bold '>  {message_data?.subject}</h6>

                        </div>
                          <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Fullname :</h6>
                         <h6 className='text-dark fw-bold '> {message_data?.fullname}</h6>

                        </div>
                          <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Email:</h6>
                         <h6 className='text-dark fw-bold '>{message_data?.email}</h6>

                        </div>
                          <div className='message_modal_container' style={{gap:"10px"}}>
                        <h6 className='text-secondary'>Message :</h6>
                        <h6 className='text-dark fw-bold'>{message_data?.msg}  </h6>

                        </div>
                    </div>
                </div>
                    </div>
              </div>
                <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
                </ModalBody>
            </Modal>
    </div>
  )
}

export default Message_view_modal