import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import Alert_Modals from '../../components/Alert_Modals';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_member_account } from '../../functions/api_call_functions';
import { set_selected_member } from '../../Store/Action';
import api from '../../Apis/api';
import { get_today_date } from '../../functions/member_functions';

function ID_Request_View({result,refresh_data,account}) {
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const handel_generate_setting=(Member_ID)=>{
       get_member_account(Member_ID).then((res)=>{
            if(res.status=="success")
            {
                dispatch(set_selected_member(res.user))
                navigate(`/Admin/Id/${res.user.username}`);
            }
            else{
                setText("Error occured while try to fetch  account please try again later ")
                setType("error");
                setDialog(true);
                setTimeout(() => {
                  setDialog(false);
                   }, 3000);
            }
    
        })

      }
      //handel request status
      const handel_request=async(request_id,account_id)=>{
        try {
            const response= await api.put('/ID/update/status',{
                request_id,
                process_date:get_today_date()
            })
            if(response.data.status=="success")
            {
                setText("ID Status updated successfuly");
                setType("success");
                setDialog(true);
                setTimeout(() => {
                    setDialog(false);
                    refresh_data()
                     }, 1000);
                     
            }
        } catch (error) {
            console.log(error);
            setText(error.response.data.error);
            setType("error");
            setDialog(true);
            setTimeout(() => {
                setDialog(false);
                 }, 1000);
        }
        


      }
      const columns=[
        {label:"UserID",name:"account_id" ,options: { filter: false,}},
        {label:"Request ID",name:"request_id" ,options: { filter: false,}},
        {label:"Request Date",name:"request_date",
        options:{
            filter: false,
            customBodyRender:(value)=>(
                moment(value ).format('YYYY-MM-DD')
            ) }
        },
        {label:"Processed Date",name:"processed_date",
            options:{
                filter: false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
            },
        {label:"Status",name:"request_status", options:{
            customBodyRender:(value)=>{
                if(value=="pending")
                { 
                return (
                    <span className='badge bg-primary'> {value}</span>
                )
                }
                else if(value=="Completed")
                {
                    return (
                        <span className='badge bg-success'> {value}</span>
                    )
                }
                else if(value=="rejected")
                {
                    return (
                        <span className='badge bg-danger'> {value}</span>
                    )
                }
                   
            }
    }},
        {label:"Action",name:"request_id", options:{
            filter: false,
            customBodyRender:(value,tableMeta)=>{
               
               return (
              
                   <div>
                    <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_request(value,tableMeta.rowData[0])}>Approve <CheckCircleIcon  className=' btn-success '/></span>
                    <span className='btn btn-primary mx-1 my-1' onClick ={()=>handel_generate_setting(tableMeta.rowData[0])}><PrintIcon/></span>
                   </div>
               
            )}
    }},
    ]
    if(  result!== undefined && result !== null )  { 
        return (
          <>
        {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
    
          <MUIDataTable 
          title={"ID Request"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
         </>
        )}
        else
        {
           return (<Alert_Modals type="error" 
            text="Sorry no Pendding request found on the system !"
              />)
          }
    
    
    }


export default ID_Request_View