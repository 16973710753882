import axios from "axios";
import { logout } from "../functions/member_functions";

const api= axios.create({
    baseURL:'https://eeva-server.ethiopianevaluationassociation.org',
    //  baseURL:'http://127.0.0.1:5004',
    withCredentials: true, // Allow sending cookies with requests
    });
    // request interceptor to add access token to headers
    api.interceptors.request.use(
        (config)=>{
           
            const accessToken=JSON.parse(localStorage.getItem("Token"));
            if(accessToken){
                config.headers['Authorization']=`Bearer ${accessToken}`;
            }
            // console.log('Request headers:', config.headers);
            return config; 
        },
        (error)=>{
            return Promise.reject(error)
        }
    );
   // Response Interceptor
api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
        
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          // Attempt to refresh the token
          const refreshResponse = await api.post('/refresh/token/');
          const { accessToken } = refreshResponse.data;
  
          // Update localStorage with the new access token
          localStorage.setItem('Token', JSON.stringify(accessToken));
  
          // Update the Authorization header with the new token
          api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

  
          // Retry the original request with the new token
          return api(originalRequest);
        } catch (refreshError) {
          // If token refresh fails, log the user out or take another action
          console.error('Token refresh failed here 1:', refreshError);
          // Perform logout or redirect to login
          logout(true)
          return Promise.reject(refreshError);
        }
      }
     
    
    else if(error.response.status === 401)
    {
        console.error('Refresh token failed here 2:', error);
        setTimeout(() => {
          logout(true);
           }, 30000);
   
        Promise.reject(error);
    }
      return Promise.reject(error);
    }
  );
    export default api;