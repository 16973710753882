import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import Alert_Modals from '../../components/Alert_Modals';
import moment from 'moment';
import Message_view_modal from './Message_view_modal';
import { update_message_status } from '../Functions/admin_functions';

function Message_viewTable ({result,refresh_data,account}) {
const [message_modal,setModal]=useState(false);
const [selected_message,setMessage]=useState(null)
const Modal_toggle=()=>{
  setModal(!message_modal);
}
    const handel_open_message=(msg_id)=>{
            const [value]=result.filter((data)=>data.msg_id==msg_id)
            setMessage(value);
            update_message_status(msg_id,account).then((res)=>{
              if(res.status=="success"&& res.result==1)
              {
                Modal_toggle();
              }
            })
     
    }
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const columns=[
        {label:"Fullname",name:"fullname",options: { filter: false,}},
        {label:"Email",name:"email",options: { filter: false,}},
        {label:"Subject",name:"subject",options: { filter: false,}},
        {label:"Sent Date",name:"sent_date"
            ,options:{
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD hh:mm:ss')
                )
            }
        },
        {label:"Read By",name:"username",options: { filter: false,}},
        {label:"Action",name:"msg_id", options:{
          filter: false,
            customBodyRender:(value)=>(
               
                   <>
                    <span className='btn btn-primary mx-1 my-1'onClick ={()=>handel_open_message(value)}><MarkChatReadIcon/></span>
                   </>
               
            )
    }},]
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      if(  result!== undefined && result !== null )  { 
        return (
            <>
            <Message_view_modal modal_status={message_modal} Modal_toggle={Modal_toggle} refresh_data={refresh_data} message_data={selected_message} />
          {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
          <MUIDataTable 
          title={"All Messages"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        /></>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no Messages found on the system !"
            />
            )
          }
      }


export default Message_viewTable