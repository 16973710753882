import React ,{useState,useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TitleHeader from '../components/TitleHeader';
import PaidIcon from '@mui/icons-material/Paid';
import SavingsIcon from '@mui/icons-material/Savings';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { CircularProgress } from '@mui/material';
import Admin_detail_cards from '../components/Admin_detail_cards';
import Member_ViewTable from '../components/Member_ViewTable';
import { get_all_members } from '../Functions/admin_functions';
import { subDays } from '../../functions/member_functions';
import { get_all_success_transaction_monthly, get_all_success_transactions_today, get_members_registerd_Monthly, get_members_registerd_today } from '../Functions/filter_functions';
function Dashbord() {
    const[allmembers,setMembers]=useState(null);
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [loadingData,setLoading]=useState(true);
   const navigate= useNavigate()
    const get_members=()=>
    {
        get_all_members().then((res)=>{

            setMembers(res)
            setLoading(false);
        })
    }

    const [gadget, setGadget]= useState({
        today_Donations:0.00,
        monthly_Donations:0.00,
        renewed_today:0,
        renewed_month:0,
        joined_today:0,
        joined_month:0,
        Membership_payment_today:0.00,
        Membership_payment_Month:0.00,

        
        
      });
      const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      useEffect(()=>{
        if (account==null)
          {
              navigate("/admin/login");
          }
          else{
            get_members();
            get_members_registerd_today().then((res)=>{
              setGadget((prev)=>(
                {
                  ...prev,
                  joined_today:res.result
                }
              ))
            });
            get_members_registerd_Monthly().then((res)=>{
              setGadget((prev)=>(
                {
                  ...prev,
                  joined_month:res.result
                }
              ))
            });
            get_all_success_transaction_monthly().then((res)=>{
              const membership=res.transactions && res.transactions.filter((data)=>data.description=="membership");
              const Donations = res.transactions && res.transactions.filter((data)=>data.description=="donation");
             
              setGadget((prev)=>(
                {
                  ...prev,
                  monthly_Donations:sumAmount(Donations),
                  Membership_payment_Month:sumAmount(membership),
                  renewed_month:membership?.length,
                }
              ))
            })
            get_all_success_transactions_today().then((res)=>{
              const membership=res.transactions && res.transactions.filter((data)=>data.description=="membership");
              const Donations = res.transactions && res.transactions.filter((data)=>data.description=="donation");
             
              setGadget((prev)=>(
                {
                  ...prev,
                  today_Donations:sumAmount(Donations),
                  Membership_payment_today:sumAmount(membership),
                  renewed_today:membership?.length,
                }
              ))
            })
            
          }
        const sumAmount=(result)=>
        {
          var sales=0;
          result && result.map((data)=>{
            sales=sales+parseFloat(data.amount);})
            return sales;
        }
         
      },[])
      const refresh_data=()=>
        { setLoading(true);
            setMembers(null);
            get_members()
        }    
  return (
    <div className='container-fluid'>
               <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<AssessmentIcon/>} title="Dashbord"/>
      </div>
      <div className='contianer-fluid mt-3 '>
        <div className='row'>
            <div className='col-sm-12 col-md mx-auto'>
                <Admin_detail_cards title="Members Registered"  today_value={gadget.joined_today} montly_value={gadget.joined_month} Icon={<GroupAddIcon className='text-warning' fontSize='large'/>}  time="Today"/>
            </div>
            <div className='col-sm-12 col-md mx-auto'>
                <Admin_detail_cards title="Membership Renewed"  today_value={gadget.renewed_today} montly_value={gadget.renewed_month} Icon={<PublishedWithChangesIcon className='text-info' fontSize='large'/>} time="Today"/>
            </div>
            <div className='col-sm-12 col-md mx-auto'>
                <Admin_detail_cards title="Members Donations" today_value={nf.format(gadget.today_Donations)} montly_value={nf.format(gadget.monthly_Donations)} Icon={<SavingsIcon className='text-primary' fontSize='large'/>} time="Today"/>
            </div>
            <div className='col-sm-12 col-md mx-auto'>
                <Admin_detail_cards title=" Membership fee" today_value={nf.format(gadget.Membership_payment_today)} montly_value={nf.format(gadget.Membership_payment_Month)}Icon={<PaidIcon className='text-success' fontSize='large'/>} time="Today"/>
            </div>
        </div>
            <div className='row mt-4 p-4 '>
                <div className='admin_color p-4 rounded'>
                <h5 className='fw-bolder'>Recently Registerd members (15 days)</h5>
              </div>
              <div className='p-2 mt-2'>
              { loadingData ? (
                                <div className="loading-container">
                                    <CircularProgress className="large-spinner" color="inherit" />
                                </div>
                            ) :( <Member_ViewTable refresh_data={refresh_data} result={allmembers && allmembers.filter((data)=>(subDays(data.created_date)>-15))}/>)}
              </div>
            </div>
      </div>

    </div>
  )
}

export default Dashbord