import React, { useState,useEffect } from 'react';
import "../css/Navbar.css";
import ListIcon from '@mui/icons-material/List';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import Admin_NavItem from './admin_navbar_data';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import { get_all_messages } from '../Functions/admin_functions';
function AdminNavbar({children}) {
  
    const [isopen, Setopen]= useState(false);
    const navigate= useNavigate();
    const [message_count,setCount]=useState(0)
    const [navBar,setData]=useState(null);
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const toggle=()=>
    {
      Setopen(!isopen);
      
    }
    useEffect(()=>{
        if (account==null)
          {
              navigate("/admin/login");
          }
          else{
                if(account.role=="Admin"){
                    setData(Admin_NavItem.filter((data)=>data.role=="Admin"||data.role=="Both"));
                }
                else if (account.role=="Content creator")
                {
                    setData(Admin_NavItem.filter((data)=>data.role=="Both"));

                }
                get_all_messages().then((res)=>{
                    const value=res.messages && res.messages.filter((msg)=>msg.status=="unread");
                    setCount(value.length)
                })
          }
        
         
      },[])
    return (
      <div className='container-fluid    p-0  h-100'>
        <div className='row'>
            <div className='col-sm-12 d-flex  w-100'>
            <div className='app_sidebar   ' style={{width:isopen? "250px":"50px"}}>
              <div className='top_section '>
                  <div className='logo container mb-3' style={{display:isopen? "block":"none"}}>
                  <img src='../Assets/EEVA_logo.png' className='sidebar_logo rounded' alt=''/> 
                  </div>
                  <div style={{fontSize:isopen? "20px":"40px"}}>
                      <ListIcon onClick={toggle} className='bars'/>
                  </div>
              </div>
            
              <div className='nav_section '>
                 
              {
                 navBar && navBar.map((nav ,index)=>{
                    if(nav.name=="Messages")
                    {
                        return(
                            <Link  key= {index} to={nav.path} className='nav_options'>
                        
                            <div className='nav_icons'>
                                {nav.icon}
                            </div>
                            <div className='nav_options_text' style={{display:isopen? "block":"none"}}>
                                <h2> {nav.name} <span className='badge bg-primary text-white'>{message_count}</span> </h2>
                                
                            </div>
                        </Link> 
                        )
                    }
                     else {
                        return(
                      <Link  key= {index} to={nav.path} className='nav_options'>
                  
                      <div className='nav_icons'>
                          {nav.icon}
                      </div>
                      <div className='nav_options_text' style={{display:isopen? "block":"none"}}>
                          <h2> {nav.name} </h2>
                      </div>
                  </Link> 
  
                      )
                    }
                  })
              }
                 
                  
            
                
                
              </div>
          </div>
          <div className=' content-container ' style={{width:isopen? "90%":"98%"}}>{children}</div>

            </div>

        </div>
               </div>
    )
  }
  


export default AdminNavbar