import React, { useEffect } from 'react'
import  "../Css_for_components/conduct.css";
function Conduct({dummy_data}) {
    useEffect(()=>{
        const options={
            rootMargin:"0px",
            threshold:0.2
        }
        const title= document.getElementById("conduct_title");
        const style= document.getElementById("conduct_container")
        const observer=new IntersectionObserver((enteries)=>{
            enteries.forEach((entry)=>{
                if(entry.isIntersecting)
                    { 
                        
                    if(entry.target.id=='conduct_title')
                   {
                    entry.target.classList.add("conduct_title_loading");
                    // observer.unobserve(entry.target);
                    }
                    else if (entry.target.id=="conduct_container")
                    {
                        entry.target.classList.add("conduct_card_style_fadeIn");
                    }
                    // observer.unobserve(entry.target);
                    }
            })
          
           
        },options)
        observer.observe(title);
        observer.observe(style);
        },[])
  return (
    <div className='container p-0 my-5'>
        <div className='row'>
                <div className='conduct_title' id="conduct_title">
                <h3 className='display-4 fw-bold w-100 text-center  mb-5 '>Codes of Conduct  </h3>
                   
                </div>
                   <div id="conduct_container" className='container-fluid   conduct_container '>
                    <div className='row p-3'>
                        {
                            dummy_data.map((data,index)=>{
                                return(
                                    <div className='col-sm-12 col-md-4  p-3 mb-4 conduct_card_container  rounded ' >
                                    <div className="card shadow p-2 h-100   conduct_card_style" >
                                    <div key={index} className='card-header conduct_card_title  border-0'>
                                    <h3 className='w-100 text-center '>{data.Icon}</h3>
                                        <h3 className='w-100 text-center '>{data.title}</h3>
                                    </div>
                                    <div className=' card-body '>
                                        <div className='d-flex justify-content-center conduct_card_body' >
                                        <ul className='' >
                                        {data.content.map((item,index)=>
                                        {
                                            return (
                                               
                                                    <li key={index}>
                                                        {item}
                                                    </li>
                                                
                                            )
                                        })}
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                )
                            })
                        }
                   </div>
                   </div>

               
        </div>
    </div>
  )
}

export default Conduct