

import PostAddIcon from '@mui/icons-material/PostAdd';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import TextsmsIcon from '@mui/icons-material/Textsms';
import GroupIcon from '@mui/icons-material/Group';
import ApprovalIcon from '@mui/icons-material/Approval';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CampaignIcon from '@mui/icons-material/Campaign';
import BadgeIcon from '@mui/icons-material/Badge';
const Admin_NavItem=[
    {
    path:"/Admin/Dashbord",
    name:"Dashbord",
    icon:<AssessmentIcon />,
    role:"Admin"
    },
    {
        path:"/Admin/Manage-Users",
        name:"Users",
        icon:<PersonAddAltIcon />,
        role:"Admin"
        },
        {
            path:"/Admin/Manage-memebership",
            name:" Manage Membership",
            icon:<GroupIcon />,
            role:"Admin"
            },
            {
                path:"/Admin/Blog-Management",
                name:"Blogs",
                icon:<PostAddIcon/>,
                role:"Both"
                },
                {
                    path:"/Admin/Announcement-Management",
                    name:"Announcement Management",
                    icon:<CampaignIcon />,
                    role:"Both"
                    },
                    {
                        path:"/Admin/Material-Managment",
                        name:"Material Managment",
                        icon:<DriveFolderUploadIcon />,
                        role:"Both"
                        },
                                  
                    {
                        path:"/Admin/Configuration",
                        name:"configuration",
                        icon:<SettingsSuggestIcon/>,
                        role:"Both",
                        },
                        {
                            path:"/Admin/Request-cross-check",
                            name:"Request-Crosscheck",
                            icon:<ApprovalIcon />,
                            role:"Admin"
                            },
                            {
                                path:"/Admin/Messages",
                                name:"Messages",
                                icon:<TextsmsIcon />,
                                role:"Both"
                                },
                            {
                                path:"/Admin/Transaction",
                                name:"Payment reports",
                                icon:<AutoGraphIcon />,
                                role:"Admin"
                                },
                                {
                                    path:"/Admin/ID-requests",
                                    name:"ID Request",
                                    icon:<BadgeIcon />,
                                    role:"Admin"
                                    },
                    
    ];
    export default Admin_NavItem ;