import React, { useState } from 'react'
import "../Css_for_components/signup_page.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../Apis/api';
import Alert_Modals from '../components/Alert_Modals';
import { set_member } from '../Store/Action';
import { get_today_date } from '../functions/member_functions';
import { get_member_account } from '../functions/api_call_functions';


function Test() {
    const [user_info,setInfo]=useState({
        firstname:"",
        lastname:"",
        gender:null,
        title:null,
        email:"",
        username:"test",
        password:"",
        phonenumber:"",
        role:"Member",
        status:"Active",
        end_date:get_today_date(),
        created_date:get_today_date()
  
    })
    const navigate=useNavigate();
    const dispatch= useDispatch();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const [pwsvisisble,SetVisisble]=useState(false);
    //for handiling password visiblity
    const togglePassword=()=>{
      SetVisisble(!pwsvisisble);
    }
    // for handling submit
    const handel_submit=async(e)=>{
        e.preventDefault();
        console.log(user_info.profile_pic)
        const formData= new FormData();
        for ( var key in user_info ) {
            formData.append(key, user_info[key]);
        }
      
 
        
         
        
        try {
            const response=await api.post("/member/profile/update",formData,
                  {
                  
                headers:{"Content-Type":"multipart/form-data"}
            })
            console.log(response);
            
        } catch (error) {
            console.log(error);
          setText(error.response.data.error)
          setType("error");
          setDialog(true);
        }
    }
    
    const handel_change=(e)=>{
        if(e.target.name=="profile_pic")
        {  const file=e.target.files[0];
         
            setInfo((pre)=>
            ( 
              {...pre,
              [e.target.name]:file
              }
            )
          )
        }else
        {
            setInfo((pre)=>
        ( 
          {...pre,
          [e.target.name]:e.target.value
          }
        )
      )
        }
        
    };

    return (
        <div className='container-fluid p-lg-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-5  p-3 '>
                        <div className='profile_update_container rounded shadow'>
                            <div className='profile_image_container'>
                                {/* <img src={user_info.profile_pic?URL.createObjectURL(user_info.profile_pic):"/Assets/blank-profile-picture.webp"} className='img-fluid' alt='profile_pic'/> */}
                                <img src="http://127.0.0.1:5004/mom id.jpg" className='img-fluid' alt='profile_pic'/>

                            </div>
                            <div className='profile_info_container'>
                                <div>
                                    <h6> First Name :<span>{user_info.firstname}</span></h6>
                                </div>
                                <div>
                                    <h6> Last Name :<span>{user_info.lastname}</span></h6>
                                </div>
                                <div>
                                    <h6> Gender :<span>{user_info.gender}</span></h6>
                                </div>
                                <div>
                                    <h6> Title :<span>{user_info.title}</span></h6>
                                </div>
                                <div>
                                    <h6> Email :<span>{user_info.email}</span></h6>
                                </div>
                                <div>
                                    <h6> Phone :<span>{user_info.phonenumber}</span></h6>
                                </div>
                                <div>
                                    <h6> Membership end date :<span>{user_info.  end_date}</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-7 p-3'>
                    <div className='bg-light rounded shadow p-3'>
                      <div className='d-flex justify-content-center'>
                      <img src='../Assets/EEVA_logo.png' alt='Logo' className=' login_page_logo img-fluid rounded'/>
                      </div>
                    <h3 className=' register_title'>Update your Profile</h3>
                        <form onSubmit={handel_submit} >
                        {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                        <div className="row">
        <div className="col-sm-12 col-lg-6">
                <div className="form-floating mb-3 mt-3">
            <input type="text" className="form-control" onChange={handel_change} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
            <label for="firstname">First Name</label>
            </div>    
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mb-3 mt-3">
      <input type="text" className="form-control" onChange={handel_change} required value={user_info.lastname}id="lastname" placeholder="enter last name" name="lastname"/>
      <label for="lastname">Last Name</label>
    </div>
        </div>
      </div>
                 
      <div className="row">
          <div className="col-sm-12 col-lg-6">
          <div className="form-floating mb-3 mt-3">
              <select className="form-control" onChange={handel_change} required value={user_info.gender}name='gender'>
                 <option value="">please select Gender</option>
                  <option value="Male">Male</option>
                  <option value="female">Female</option>
              </select>
              <label for="Gender">Gender</label>
            </div>    
          </div>
        <div className="col-sm-12 col-lg-6">
          <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={handel_change} required value={user_info.title} name='title'>
                    <option value="">---please select Title---</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                    <option value="Prof">Prof</option>
                </select>
                <label for="title">Title</label>
          </div>
        </div>
      </div>
          <div className="form-floating mb-3 mt-3">
            <input type="username" disabled="true" className="form-control" value={user_info.username}onChange={handel_change} required id="username" placeholder="Enter username" name="username"/>
            <label for="Username">Username</label>
        </div>
    
          <div className="input-group form-floating mt-3 mb-3 ">
              <input type={pwsvisisble?"text":"password"} className="form-control" value={user_info.password} onChange={handel_change} required id="pwd" placeholder="Enter password" name="password"/>
              <label for="pwd">Password</label>
              <span className="input-group-text" onClick={togglePassword}><VisibilityIcon/></span>
            </div>
    
          <div className="row">
            <div className="col-sm-12 col-lg-6">
            <div className="form-floating mt-3 mb-3">
          <input type="email" className="form-control"  value={user_info.email} onChange={handel_change} required id="email" placeholder="Enter email" name="email"/>
          <label for="email">Email</label>
        </div>
            </div>
            <div className="col-sm-12 col-lg-6">
            <div className="form-floating mt-3 mb-3">
              <input type="text" className="form-control" value={user_info.phonenumber} onChange={handel_change} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
          <label for="phonenumber">Phonenumber</label>
        </div>
        </div>
    </div>
    <div className="form-floating mb-3 mt-3">
            <input type="file" className="form-control" accept=".jpg, .jpeg, .png" onChange={handel_change} required id="profile_pic" placeholder="Upload profile" name="profile_pic"/>
            <label for="profile_pic">profile pic</label>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <button type='submit' className='btn btn-success w-100 fw-bolder mx-auto'>Update</button>
         </div>
         
     </form>
                    </div>
                </div>
                </div>
            </div>
    
        </div>
      )
}

export default Test