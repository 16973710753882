import React from 'react'

function Alert_Modals({text, type}) {
    if(type=="error")
    {
        return (
      <div className="alert alert-danger alert-dismissible visible">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
              <strong>Error!</strong> <h6 className='text-danger' >{text} !</h6>
          </div>
    )}
    else if (type=="success")
    {
      return (
      <div className="alert alert-success alert-dismissible visible">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
              <strong>Success!</strong> <h6 className='text-success'>{text}</h6>
          </div>
    )}
    else if (type=="warning")
      {
        return (
        <div className="alert alert-warning alert-dismissible visible">
          <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <strong>Sorry!</strong> <h6 className='text-success'>{text}</h6>
            </div>
      )}
  }


export default Alert_Modals