import React, { useEffect,useState } from 'react'
import TitleHeader from '../components/TitleHeader'
import { Link, useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import RefreshIcon from '@mui/icons-material/Refresh';
import SimpleBackdrop from"../components/SimpleBackdrop";
import { get_today_date } from '../../functions/member_functions';
import * as XLSX from 'xlsx'
import moment from 'moment';
import Transaction_Admin_view from '../components/Transaction_Admin_view';
import { get_all_transactions } from '../Functions/admin_functions';
import api from '../../Apis/api';


export default function Transaction_management() {
    const [datafilters,setFilters]=useState({
        start_date:null,
        end_date:null,
        reason:null,
        status:null
    }); 
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [alert_dialog,setDialog]=useState(false);
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const navigate= useNavigate();
    const [allTransactions,setTransactions]=useState(null);
    const handel_change=(e)=>{
        setFilters( (prev)=>({
          ...prev,
          [e.target.name]:e.target.value
        }))
      }
      //making api request to server to get all transactions
    const fetch_data=()=>{
        get_all_transactions().then((res)=>{
            setTransactions(res.transactions);
        })
    }
   //for exporting all the transaction data that is put inside the table
    const export_data=()=>{
        var wb= XLSX.utils.book_new();
         var ws=XLSX.utils.json_to_sheet(allTransactions);
         XLSX.utils.book_append_sheet(wb,ws,"Transactions");
         XLSX.writeFile(wb,"MyExcel.xlsx")
    }
    //for refreshing the data
    const refresh_data=()=>{
        setTransactions(null);
        fetch_data()
        setFilters({
          start_date:"",
          end_date:"",
          reason:null,
        status:null
      })
    }
    //for handling the submit of the filter data
    const onhandelSubmit=async(e)=>{
        e.preventDefault();
        console.log(datafilters);
        if(datafilters.start_date==null&&datafilters.end_date!==null)
        { 
            setDialog(true);
            setText("Start Date can't be empty");
            setType("error");
        }
        try {
            setTransactions(null);
            const response= await api.post("/Transaction/get/filters",{
                datafilters
            })
            if(response.data.status=="success")
            {
                setTransactions(response.data.transaction);
            }
            
        } catch (error) {
            console.log(error);
            setText("Internal server Error occured while try to fetch Data please try again ")
            setType("error");
            setDialog(true);
            setTimeout(() => {
              setDialog(false);
            }, 3000);
            
        }
        
    }
    useEffect(()=>{
        if (account==null)
          {
              navigate("/admin/login");
          }
          else{
            fetch_data();
          }
        
         
      },[])
  return (
    <div className='container-fluid'>
         <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<AutoGraphIcon/>} title="Payment Management"/>
      </div>
      <div className='container-fluid admin_container_color  '>
      <div className='row admin_container_color '> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-4 '>
              {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                <div className='d-flex justify-content-around  '>
               

                    <form onSubmit={onhandelSubmit} className='d-flex justify-content-around w-100'>
                   
                      <div className=''>
                          <div class="mb-3 px-3 ">
                        <label for="Start_date" class="form-label text-dark">Start Date:</label>
                        <input type="Date" onChange={handel_change} required value={datafilters.start_date} max={moment(get_today_date() ).format('YYYY-MM-DD')} className="form-control" id="start_date"  name="start_date"/>
                        </div>
                      </div>
                      <div className=''>
                      <div class="mb-3 px-3  ">
                    <label for="end_date" class="form-label text-dark">End Date:</label>
                    <input type="Date" onChange={handel_change} required value={datafilters.end_date} className="form-control" id="end_date " max={moment(get_today_date() ).format('YYYY-MM-DD')}  name="end_date"/>
                    </div>
                      </div>
                      <div class="mb-3 px-3  ">
                      <label for="end_date" class="form-label text-dark">Reason:</label>
                                        <select className="form-control"  value={datafilters.reason} onChange={handel_change}  name='reason'>
                                            <option value="">Reason</option>
                                            <option value="membership">Membership Renewal</option>
                                            <option value="donation">Donation</option>
                                            <option value="ID">Digital ID payment</option>
                                        </select>
                                    
                        </div>  
                        <div class="mb-3 px-3  ">
                      <label for="end_date" class="form-label text-dark">Status:</label>
                                        <select className="form-control"  value={datafilters.status} onChange={handel_change}  name='status'>
                                            <option value="">Status</option>
                                            <option value="Pending">Pending </option>
                                            <option value="Success">Success</option>
                                            <option value="Declined">Declined</option>
                                        </select>
                                    
                        </div>  
                      <div className=''>
                      <div className='my-lg-4 py-lg-2 w-100'>
                        <button type='submit' className='btn btn-success w-100'>Search</button>
                    </div>
                      </div>
                    
                    </form>
                </div>

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                <button className='btn btn-primary fw-bold mx-2' onClick={export_data} >Export <ImportExportIcon/></button>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
        {allTransactions ? <Transaction_Admin_view result={allTransactions} refresh_data={refresh_data} className="text-dark"/> : <SimpleBackdrop/>}
        </div>
        </div>
      </div>
    </div>
  )
}
