import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import { useSelector,useDispatch } from 'react-redux';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import Alert_Modals from '../../components/Alert_Modals';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import moment from 'moment';
import { deactivate_account ,activate_account} from '../Functions/admin_functions';
import { useNavigate } from 'react-router-dom';
import { set_selected_member } from '../../Store/Action';
import MemberSetting from './MemberSetting';
function Member_ViewTable({result,refresh_data}) {
    const navigate=useNavigate();
 
    
    const dispatch=useDispatch();
    const handel_Activation=(account_id)=>
    {
        activate_account(account_id).then((res)=>{
            refresh_data();
        })
    }
    const handel_Deactivation=(account_id)=>
    {
        deactivate_account(account_id).then((res)=>{
            refresh_data();
        })
    }
    const handel_generate_setting=(account_id)=>{
        const [account]= result.filter((data)=>data.account_id==account_id);
       
        dispatch(set_selected_member(account));
        navigate("/Admin/Member/role-config")
       
    }
  ;
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const columns = [
       
        {label:"Firstname",name:"firstname" ,options: { filter: false,}},
        {label:"Lastname",name:"lastname" ,options: { filter: false,}},
        {label:"Gender",name:"gender"},
        {label:"Phonenumber",name:"phonenumber" ,options: { filter: false,}},
        {label:"Email",name:"email" ,options: { filter: false,}},
        {label:"Role",name:"org_role"},
        
        {label:"Status",name:"status",options:{
            customBodyRender:(value)=>{
                if(value=="Active")
                { return (
                    <span className=' btn btn-info  p-1  text-white rounded fw-bold '>{value}</span>
                )}
                else if(value=="inactive")
                {
                    return (
                        <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
            }
        }},
        {label:"Start Date",name:"created_date",
            options:{
                filter: false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"End Date",name:"membership_enddate",
            options:{
                filter: false,
                customBodyRender:(value)=>(
                    moment(value ).format('YYYY-MM-DD')
                ) }
        },
        {label:"Action",name:"account_id", options:{
            filter: false,
                customBodyRender:(value)=>(
                   
                       <div>
                        <span className=' btn btn-success rounded mx-1 my-1'onClick ={()=>handel_Activation(value)}>Activate<LockOpenIcon  className=' btn-success '/></span>
                        <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                        <span className='btn btn-primary mx-1 my-1' onClick ={()=>handel_generate_setting(value)}><SettingsSuggestIcon/></span>
                       </div>
                   
                )
        }},

    ];

    if(  result!== undefined && result !== null )  { 
        return (
          <>

          <MUIDataTable 
          title={"Members Account report"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
         </>
        )}
        else
        {
           return (<Alert_Modals type="error" 
            text="Sorry no Members found on the system !"
              />)
          }
}

export default Member_ViewTable