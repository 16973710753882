import React, { useEffect,useState } from 'react'
import Contact_us_form from '../components/Contact_us_form'
import "../Css_for_components/Contact_us_page.css"
import { DataArray, Mail, Phone } from '@mui/icons-material'

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from '@mui/material';
import { get_all_board_memebers } from '../functions/api_call_functions';
function Contact_us_page() {
    const [contact_person,setPerson]=useState(null);
    
    useEffect(()=>{
        get_all_board_memebers().then((res)=>{
            const filterd=res.members.filter((data) =>(data.org_role=="President" || data.org_role=="Communication director"))
            setPerson(filterd);
        })
    },[])

  return (
    <div className='container-fluid p-0'>
            <div className='row'>
                <div className='col-sm-12'>
                <div className="Page_title_container">
                    <div className='container'>
                    <h5>Please Reach Out</h5>
                    </div>
                </div>
                 </div>
                 </div>
                 <div className='container'>
                    <div className='row'>
                       {contact_person && contact_person.map((data,index)=>{
                           return(
                            <div className='col-sm-12 col-lg-6 p-4 ' key={index} >
                            <div className="card contact_page_img_container shadow " >
                                <img className="card-img-top rounded" style={{height:"350px", objectFit:"contain"}} src={`https://eeva-server.ethiopianevaluationassociation.org/Profile/${data.profile_image}`} alt="Card image"/>
                                <div className="card-body">
                                    <h4 className="contact_person_name">{data.firstname} {data.lastname}</h4>
                                    <p className="contact_person_title">{data.org_role} of EEvA.</p>
                                    <p className='contact_person_quote'>{data.bio}</p>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex my-1 contact_us_icons'>
                                                <Phone/>  <span className='contact_person_quote mx-3'>{data.phonenumber}</span>
                                            </div>
                                            <div className='d-flex my-1 contact_us_icons'>
                                                <Mail/> <span className='contact_person_quote mx-3'>{data.email} </span>
                                            </div>
                                        </div>
                                        <div className=' d-flex my-2 '>
                                        
                          
                            <div className='contact_social_media_icon_container'>
                              <Link to={data.Linkedin_link!==null?data.Linkedin_link:"#"}><LinkedInIcon/></Link>  
                            </div>
                        
                                            </div>
    
                                    </div>
                                 <a target="_blank" className='w-100 bg-danger' href={`https://dev.ethiopianevaluationassociation.org/Members/Profile-view/${data.username}`}><button className='btn btn-primary float-right'>See Profile</button></a>   
                                   
                                </div>
                            </div>   
                            </div>
                           )
                       })
                       
                       }

                    </div>

                 </div>
            
        <Contact_us_form/>
       </div>
  )
}

export default Contact_us_page