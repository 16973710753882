import React ,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import PreviewIcon from '@mui/icons-material/Preview';
import Alert_Modals from '../../components/Alert_Modals';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import PaidIcon from '@mui/icons-material/Paid';
import Detail_Cards from './Detail_Cards';
import { Filter } from '@mui/icons-material';
import Transaction_details_modal from './Transaction_details_modal';
function Transaction_Admin_view( {result,refresh_data}) {
    var sales=0;
    var count=result.length;
    const [selectedTransaction,setTransaction]=useState();
    const handelpreview=(id)=>{
        const [value]=result.filter((data)=>data.transaction_id==id);
        setTransaction(value);
        toggle();
    }
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const [modal_status,setModal]=useState(false);
    const toggle=()=>setModal(!modal_status);
    const columns=[
        {label:"Transaction ID",name:"transaction_id", options: {
            filter: false,
            
           }},
        {label:"Transaction reference ",name:"gateway_transaction_id",options: {
            filter: false,
            
           }},
        {label:"Reason",name:"description"},
        {label:"Amount",name:"amount",options: {
            filter: false,
            customBodyRender:(value,tableMeta)=>{
                return(
                   <h5> <span className="badge bg-success text-light p-2 rounded fw-bold">{nf.format(value)} </span></h5>
                )
            }
            
           }},
        {label:"Currency",name:"currency",options: {
            filter: false,
            
           }},
        {label:"Date",name:"timestamp", options:{
            filter:false,
            customBodyRender:(value)=>(
                moment(value ).format('YYYY-MM-DD')
            ) }
    },
        {label:"Status",name:"status", options:{filter: true,
            customBodyRender:(value)=>{
                if(value=="Success")
                    { 
                    return (
                        <span className='badge bg-success'> {value}</span>
                    )
                    }
                    else if(value=="Declined")
                    {
                        return (
                            <span className='badge bg-danger'> {value}</span>
                        )
                    }
                    else if(value=="Pending")
                        {
                            return (
                                <span className='badge bg-primary'> {value}</span>
                            )
                        }
             } }
    },
    {label:"Action",name:"transaction_id", options:{ filter:false,
        customBodyRender:(value)=>(
           
               <div onClick={()=>handelpreview(value)}>
                <span className='btn btn-primary mx-1 my-1' ><PreviewIcon/></span>
               </div>
           
        )
}},
        
    ]
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      if(  result!== undefined && result !== null )  { 
        result && result.map((data)=>{
            sales=sales+parseFloat(data.amount);})
        return (
            
            <>
            <Transaction_details_modal modal_status={modal_status} Modal_toggle={toggle} refresh_data={refresh_data} transaction_data={selectedTransaction}/>
      {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
      
          <MUIDataTable 
          title={"All Your Transactions"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        />
        <div className='row mt-4'>
        <div className='col-sm-12 col-md-4 mx-auto' >
            <Detail_Cards title="Sales | ETB" value={nf.format(sales)} Icon={<PaidIcon className='text-success' fontSize='large'/>}/>
        </div>
        <div className='col-sm-12 col-md-4 mx-auto' >
         <Detail_Cards title="Transactions" value={count} Icon={<StackedBarChartIcon className='text-primary' fontSize='large'/>}/>

        </div>

    </div>
        </>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no Announcements found on the system !"
            />
            )
          }
      }




export default Transaction_Admin_view