import React, { useEffect, useState } from 'react'
import TitleHeader from '../components/TitleHeader';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import SimpleBackdrop from '../components/SimpleBackdrop';
import ApprovalIcon from '@mui/icons-material/Approval';
import Request_View from '../components/Request_View';
import { get_all_request } from '../Functions/admin_functions';
function Request_page() {
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const [allRequest,setRequest]=useState(null);
    const navigate=useNavigate();
    useEffect(()=>{
        if (account==null)
        {
            navigate("/admin/login");
        }
        else{
         fetch_data();
        }

    },[])
    const refresh_data=()=>{
        setRequest(null);
        fetch_data();
    }
    const fetch_data=()=>{
        get_all_request().then((res)=>{
            setRequest(res.request);
        })
    }
 
  return (
    <div className='container-fluid'>
                <div className='container-fluid whole_title_container shadow '>
    <TitleHeader icon={<ApprovalIcon/>} title="Request Management"/>
    </div>
    <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
          {allRequest ? <Request_View result={allRequest} refresh_data={refresh_data} account={account?.account_id} className="text-dark"/>:<SimpleBackdrop/>}
        </div>

      </div>
    </div>
  )
}

export default Request_page