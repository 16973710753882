import React,{useEffect} from 'react'
import "../Css_for_components/Objective_section.css"
function Objectives_section() {
    const options={
        rootMargin:"0px",
        threshold:0.2
    }

    
    useEffect(() => {
        const first_section= document.getElementById('objective_title');   
   
        const content_section=document.getElementById('objective_content_section');
    const observer=new IntersectionObserver((entries)=>{
        entries.forEach((entry)=>{
           if( entry.isIntersecting)
           {
            entry.target.classList.add("objective_fade_in");
               
           }
        })


    },options);
    observer.observe(first_section);
    observer.observe(content_section);
}, [])
  return (
    <div className='container-fluid p-1 my-3' >
       <div  className='container my-3'>
       <div className='row'>
           <div >
           <h2 className='section_title my-3' id="objective_title">EEVA Objective</h2>
      
           </div>
              
              <div className=' row objective_content_section' id="objective_content_section">
              <div className='col-sm-12 col-lg-4 p-2 mx-auto mt-2 rounded'>
                <div className='objective_section_container shadow rounded'>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2024-06-29_10-27-05.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                    <div>
                        <h5> Create forms</h5>
                        <h6>
                        Create forums and platforms for profession-al evaluators,
                         policymakers, academia, development practitioners, and private 
                         sector actors to exchange knowledge, evidence, 
                        and shared learning on national and regional issues of evaluation practices.
                        </h6>
                    </div>

                </div>

          </div>
           <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2024-06-29_10-26-55.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5>Encourage evaluation development</h5>
                        <h6>
                            Encourage the development and publication of high-quality evaluation practices and evaluation theories based on local experiences and expertise thereby enhancing the building of evaluation capacity in Ethiopia.                    
                        </h6>
                    </div>

                </div>
          </div>
          <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2023-07-26_17-42-35.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5>Multidisciplinary evaluation</h5>
                        <h6>
                            Foster and promote multidisciplinary evaluation practice at national and regional levels.                      
                        </h6>
                    </div>

                </div>
          </div>
          <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2024-03-18_13-23-20.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5> Serve as a reference</h5>
                        <h6>
                            Serve as a reference point for evaluation information and methodology relevant to Ethiopia and the region.                       
                        </h6>
                    </div>

                </div>
          </div>
          <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2023-07-26_17-28-28.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5>  Share Ethiopian evaluation experience</h5>
                        <h6>
                            Promote and share Ethiopian evaluation experience and expertise at relevant regional and international forums and events.                      
                        </h6>
                    </div>

                </div>
          </div>
          <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2024-03-20_11-07-17.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5> Develop and maintain professional relationships</h5>
                        <h6>
                            Develop and maintain professional relationships with other similar local and international organizations with a view toward fostering the growth of professional evaluation practice in Ethiopia.                          </h6>
                    </div>

                </div>
          </div>
          <div className='col-sm-12 col-lg-4  p-2 mx-auto mt-2'>
           
                <div className='objective_section_container shadow '>
                <div className='objective_media_container' >
                    <img src="../Assets/blog/photo_2023-07-26_17-28-17.jpg" alt='blog-1'className='objective_media img-fluid rounded  '/>

                </div>
                <div>
                        <h5>  Promote evaluation as a profession,</h5>
                        <h6>
                            enhance evaluative thinking, and support national ownership of evaluation practice.                         </h6>
                    </div>

                </div>
          </div>
       
          </div>
             
        
        </div>
        </div>
    </div>
  )
}

export default Objectives_section