import React, { useState } from 'react';
import "../css/Admin_loginpage.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../Apis/api';

import {get_user_account} from "../Functions/admin_functions"
import { useDispatch, useSelector } from 'react-redux';
import Alert_Modals from '../../components/Alert_Modals';
import { set_user_state } from '../../Store/Action';
import Password_change_modal from '../../components/Password_change_modal';
import Forgotpassword from '../../components/Forgotpassword';

function Admin_Loginpage() {
    const navigate=useNavigate();
    const dispatch= useDispatch();
    const [modal_status,setStatus]=useState(false);
    const [pws_change,setPws_change]=useState(false);
    const[account,setAccount]=useState();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const[showpassword,setShow]=useState(false);
    const[user_info,SetInfo]=useState({
        
        username:"",
        password:""
    });
    const Modal_toggle=()=>
      {
        setStatus(!modal_status);
      }
      const pws_toogle=()=>{
          setPws_change(!pws_change);
      }

     const toogle_password=()=>{
        setShow(!showpassword);
    }
    // for hadeling change in the input field
    const onHandelchange=(e)=>{
        SetInfo( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }));
}
//handel submit
const onHandelsubmit=async(e)=>{
  e.preventDefault();
    try {
        const response= await api.post("/Login/member",{
            user_info
        })
        if(response.data.status=="success")
        {   
            if(response.data.result.status=="Active")
            {
              localStorage.setItem("Token",JSON.stringify(response.data.token));
                get_user_account(response.data.result.account_id).then((res)=>{
                    if(res.status==="success")
                    { if(res.result==0)
                      {
                        setText("As a Member you can not access this site please use your Admin Detail instade")
                        setType("error");
                        setDialog(true);
                        setTimeout(() => {
                          setDialog(false);
                        }, 1000);
                      }
                      else
                      {
                        
                      if(res.user.activation=="Active")
                      { dispatch(set_user_state(res.user));
                        localStorage.setItem("Admin_user_account",JSON.stringify(res.user))
                        localStorage.setItem("Token",JSON.stringify(response.data.token))
                        setText("Login successful ")
                        setType("success");
                        setDialog(true);
                        setTimeout(() => {
                          if(res.user.role=="Admin"){
                          navigate("/Admin/dashbord")}
                          else{
                            navigate("/Admin/Blog-Management");
                          }
                        }, 1000);
                      }
                        
                      else
                      {
                        setAccount(res.user);
                         pws_toogle();
                      }
                      }
                    }
                    else{
                      setText("Error occured while try to fetch you account please try loging in from login page ")
                      setType("error");
                      setDialog(true);
                      setDialog(true);
                        setTimeout(() => {
                          setDialog(false);
                        }, 2000);
                    }
                  }) 
                   
            }
            else{
                setText("Please contact you system Admin your account is not active ")
                setType("error");
                setDialog(true);
                setDialog(true);
                        setTimeout(() => {
                          setDialog(false);
                        }, 2000);
            }
        }
        else
        {
          setText(response.data.msg)
          setType("error");
          setDialog(true);
          setTimeout(() => {
              setDialog(false);
            }, 2000);
        }
        
    } catch (error) {
    console.log(error);
  setText(error.response.data.msg)
  setType("error");
  setDialog(true);
    }
}
  return (
    <>
    <div className='container  rounded '>
    <div className='row'>
        <div className='col mb-2 d-flex justify-content-center'>
            <img src='../Assets/EEVA_logo.png' alt='Logo' className=' login_page_logo img-fluid rounded'/>
        </div>
    </div>
    <div className='row  welcome_text'>
    <h4 className=' w-100  text-center '>
        Welcome Back !!
    </h4>
    <h6 className='text-center text-dark w-100'>Please Enter your Details</h6>

    </div>
    </div>
    <div className='container-fluid full-height  '>
        <div className='row '>
            <div className='col-sm-12  col-lg-7  h-100 mx-auto'>
                <div className=' admin_login_container shadow rounded'>
                    <form className='px-lg-1' onSubmit={onHandelsubmit}>
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="mb-4 mt-3">
                    <label  className="admin_login_lable">Username</label>
                    <input type="username" required className="form-control admin_login_inputs" id="Username" onChange={onHandelchange} value={user_info.username} placeholder="" name="username"/>
                </div>
                <div className="mb-4">
                    <label  className="admin_login_lable">Password</label>
                    <div className="input-group">
                        <input type={showpassword?"text":"password"} required className="form-control admin_login_inputs" onChange={onHandelchange} value={user_info.password}  id="pwd" placeholder="" name="password"/>
                        <span className="input-group-text admin_password_icon_container p-0" onClick={toogle_password}><VisibilityIcon  className='password_icon'/></span>
                     </div>
                </div>
                <div className=' d-lg-flex justify-content-between p-1 '>
                <div className="form-check mb-3">
                    <label className="form-check-label admin_login_lable">
                    <input className="form-check-input" type="checkbox" name="remember"/> Remember me
                    </label>
                    </div>
                       <div className='mb-3'>
                       <span className='text-dark forgot_password' onClick={Modal_toggle}>Forgot password ?</span>
                       </div>
                   
                    
                    
                </div>
                <button type="submit" className="btn btn-success fw-bolder w-100">Submit</button>
                    </form>
                </div>
            </div>

        </div>
        <Password_change_modal modal_status={pws_change} Modal_toggle={pws_toogle} account={account}/>
       <Forgotpassword modal_status={modal_status} Modal_toggle={Modal_toggle}/>
    </div>
    </>
  )
}

export default Admin_Loginpage