import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import moment from "moment";
import { Edit } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';
import Alert_Modals from '../../components/Alert_Modals';
import api from '../../Apis/api';
import { get_today_date } from '../../functions/member_functions';
function Announcement_viewTable({result,refresh_data}) {
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    //for changing the post date today so that the announcemnet could go live starting from today
    const  handel_live=async(ac_id)=>{
        try {
        
            const response=await api.put(`/Announcement/go-live/${ac_id}/${get_today_date()}`)
            if(response.data.status=="success")
            {
                setDialog(true);
                setType("success")
                setText("Announcement has been Made live starting from now ")
                setTimeout(() => {
                    setDialog(false);
                    refresh_data()
                  }, 1500);
            }
            
        } catch (error) {
            console.log(error);
            setDialog(true);
            setType("error");
            setText("Internal Server Error occured while trying Deactivate the Announcement")
            setTimeout(() => {
             setDialog(false);
             refresh_data()
           }, 1500);
        } 
    }
    // this function will handel deactivation of the announcement 
    const handel_Deactivation=async(ac_id)=>
        {
            try {
        
                const response=await api.put(`/Announcement/Deactivate/${ac_id}`)
                if(response.data.status=="success")
                {
                    setDialog(true);
                    setType("success")
                    setText("Announcement has been successfuly Deactivated")
                    setTimeout(() => {
                        setDialog(false);
                        refresh_data()
                      }, 1500);
                }
                
            } catch (error) {
                console.log(error);
                setDialog(true);
                setType("error");
                setText("Internal Server Error occured while trying Deactivate the Announcement")
                setTimeout(() => {
                 setDialog(false);
                 refresh_data()
               }, 1500);
            } 
        }
    const handel_delete=async(ac_id)=>
        {
            if( window.confirm("Are you sure you want to remove this Announcement?"))
                {try {
                 const response= await api.delete(`/Announcement/Delete/${ac_id}`)
                 if(response.data.status=="success")
                 {
                     setDialog(true);
                     setType("success")
                     setText("Announcement has been successfuly removed")
                     setTimeout(() => {
                         setDialog(false);
                         refresh_data()
                       }, 1500);
                 }
                } catch (error) {
                    console.log(error);
                    setDialog(true);
                    setType("error");
                    setText("Internal Server Error occured while trying to remove the Announcement")
                    setTimeout(() => {
                     setDialog(false);
                     refresh_data()
                   }, 1500);
                 }}
        }
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const columns=[
        {label:"Blog ID",name:"ac_id" ,options: { filter: false,}},
        {label:"Blog title",name:"title", options: { filter: false,}},
        {label:"Author",name:"username"},
        {label:"Content",name:"content", options: { filter: false,}},
        {label:"Post Date",name:"post_date",  options:{ filter: false,
            customBodyRender:(value)=>(
                moment(value ).format('YYYY-MM-DD')
            ) }
    },
    {label:"End Date",name:"end_date",  options:{ filter: false,
        customBodyRender:(value)=>(
            moment(value ).format('YYYY-MM-DD')
        ) }
},
    {label:"Status",name:"status",options:{
        customBodyRender:(value)=>{
            if(value=="Active")
            { return (
                <span className=' btn btn-primary  p-1  text-white rounded fw-bold '>{value}</span>
            )}
            else if(value=="inactive")
            {
                return (
                    <span className='btn btn-danger text-white p-1 rounded fw-bold'>{value}</span>
                )
            }
            else if(value=="Suspended")
                {
                    return (
                        <span className='btn btn-info text-white p-1 rounded fw-bold'>{value}</span>
                    )
                }
        }
    }},
    {label:"Action",name:"ac_id", options:{ filter: false,
        customBodyRender:(value)=>(
           
               <>
                <span className='btn btn-primary mx-1 my-1'onClick ={()=>handel_live(value)}><PublishIcon/></span>
                <span className='btn btn-warning mx-1 my-1'onClick ={()=>handel_Deactivation(value)}>Deactivate <LockIcon  className='btn-danger'/></span>
                <span className='btn btn-danger mx-1 my-1'onClick ={()=>handel_delete(value)}><DeleteIcon/></span>
               </>
           
        )
}},
];
if(  result!== undefined && result !== null )  { 
    return (
        <>
  {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
  
      <MUIDataTable 
      title={"All Announcements"}
      data={result}
      columns={columns}
      options={options}
      className="text-color"
    /></>
    )}
    else
    {
      return (
      <Alert_Modals type="error" 
      text="Sorry no Announcements found on the system !"
        />
        )
      }
  }

export default Announcement_viewTable