import React,{ useEffect ,useState} from 'react';
import { ArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from '../Apis/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import { get_today_date } from '../functions/member_functions';
import moment from 'moment';
import { get_a_member_transaction } from '../functions/api_call_functions';
import Transaction_member_view from '../components/Transaction_member_view';
import { CircularProgress } from '@mui/material';
function Transactions_page() {
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Member_account")))||null);
    const [transaction,setTransaction]=useState(null)
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const navigate=useNavigate();
    const [datefilters,setFilters]=useState({
        start_date:"",
        end_date:""
    });
    const fetch_data=()=>{
        get_a_member_transaction(account.account_id).then((res)=>{
            if(res.status=="success")
            {   
                setLoadingTransactions(false);
                setTransaction(res.transaction);
            }
        
    })
    
    }
    const onhandelchange=(e)=>{
        setFilters((prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
    }
    const onhandelSubmit=async(e)=>{
        e.preventDefault();
        console.log(datefilters);
        setLoadingTransactions(true)
        try {
            const response= await api.post("/transaction/get/id/filter",{
                account_id:account.account_id,
                start_date:datefilters.start_date,
                end_date:datefilters.end_date
            })
            console.log(response);
            if(response.data.status=="success")
            {
                setTransaction(response.data.transaction);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setLoadingTransactions(false)
        }
    }
    const refresh_data=()=>
    {       
            setLoadingTransactions(true)
            setTransaction(null);
            fetch_data()
    }
    useEffect(()=>{
        if (account==null)
        {
            navigate("/Loginpage");
        }
        else{
               fetch_data()
        }
    },{})
    return (
    <div className='container-fluid'>
              <div className='row'>
            <div className='col-sm-12 p-5 about_us_page_title_container'>
                <div className='d-flex'>
                    <h5>{account.username}</h5><ArrowRight/><h5 className='text-secondary'>Transactions</h5>
                </div>
                <div className='mt-3'>
                    <h2>Transactions</h2>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12 col-lg-4 mx-auto  rounded my-3 '>
                   <div className='shadow rounded  p-3' >
                   <h3 className='text-dark text-center fw-bold'>Filters</h3>
                    <form onSubmit={onhandelSubmit}>
                    <div class="mb-3 px-3 mt-3">
                    <label for="Start_date" class="form-label text-dark">Start Date:</label>
                    <input type="Date" className="form-control" value={datefilters.start_date} onChange={onhandelchange} required id="start_date"  name="start_date"/>
                    </div>
                    <div class="mb-3 px-3  mt-3">
                    <label for="end_date" class="form-label text-dark">End Date:</label>
                    <input type="Date" className="form-control" value={datefilters.end_date} onChange={onhandelchange} required id="end_date " max={moment(get_today_date() ).format('YYYY-MM-DD')}  name="end_date"/>
                    </div>
                    <div className='px-3'>
                        <button type='submit' className='btn btn-success w-100'>Search</button>
                    </div>
                    </form>
                   </div>
                </div>
                <div className='col-sm-12 col-lg-7 mx-auto shadow rounded my-3 p-3'>
                    <div className='d-flex justify-content-end w-100 p-3'>
                        <button className='btn btn-primary' onClick={refresh_data}> <RefreshIcon/></button>
                    </div>
                   { loadingTransactions ? (
                                <div className="loading-container">
                                    <CircularProgress className="large-spinner" color="inherit" />
                                </div>
                            ) :(<Transaction_member_view result={transaction} refresh_data={refresh_data}/>)}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Transactions_page