import { Announcement, Try } from "@mui/icons-material";
import api from "../../Apis/api"
export function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }
    
    return randomString;
  }
export const get_all_members=async()=>{
    const response= await api.get ("/memeber/get-all/")
    if(response.data.status=="success")
    {
        return response.data.member;
    }
    else
    {
        return null;
    }
}
export const get_all_users=async()=>{
    const response= await api.get ("/users/get-all/")
    if(response.data.status=="success")
    {
        return response.data.users;
    }
    else
    {
        return null;
    }
}
//for deactivate account
export const deactivate_account=async(account_id)=>
{
    const response= await api.put("/account/deactivate/",{
            account_id    
    });
    console.log(response);
    if(response.data.status=="success")
    {
        return true;
    }
    else
    {
        return false;
    }
}
//for deactivate account
export const activate_account=async(account_id)=>
{
    const response= await api.put("/account/activate/",{
            account_id    
    });
    console.log(response);
    if(response.data.status=="success")
    {
        return true;
    }
    else
    {
        return false;
    }
}

// username validation
export const username_validation=async(username)=>{

    try {
      const response= await api.get(`/Account/check/${username}`)
   
      if(response.data.result!==0)
      {
        return true;
      }
      else
      {
        return false;
      }
  
    } catch (error) {
        console.log(error);
        return false;

    }
  
  }
  //get user account details
  export const get_user_account=async(account_id)=>
{   
    try {
        const response =await api.get(`/get-user/${account_id}`)
       
        return({
            status:response.data.status,
            user:response.data.user,
            result:response.data.result
        })

    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}
//get all blogs from database
 export const get_all_Blogs=async()=>{

    try{
        const response= await api.get("/Blog/get-all");
        return({
            status:response.data.status,
            Blog:response.data.Blog,
            result:response.data.result
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 // get images related to the blog
 export const get_blog_images=async(blog_id)=>{

    try{
        const response=await api.get(`/Blog/get-images/${blog_id}`);
        return({
            status:response.data.status,
            images:response.data.images,
            result:response.data.result,
        })

    }catch(error)
    {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const remove_blog_images=async(img_id,img_path)=>{
     try {
         const response=await api.delete(`/Blog/Delete-images/${img_id}/${img_path}`)
         return({
            status:response.data.status,
            result:response.data.result,
        })
     } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
     }
 }
 //get all announcements
 export const get_all_announcements=async()=>{

    try{
        const response= await api.get("/Announcement/get-all");
        return({
            status:response.data.status,
            announcement:response.data.Announcement,
            result:response.data.result
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const activate_announcements=async(anc_id)=>
 {
     try{
        const response= await api.put(`/Announcement/Activate/${anc_id}`);
        return({
            status:response.data.status,
            result:response.data.result
        })
          
     }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 // get all materials

 export const get_all_materials=async()=>
 {
     try {
         const response= await api.get("/Materials/get-all");
         return({
            status:response.data.status,
            material:response.data.material,
            result:response.data.result
        })
     } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
     }
 }
 //check if there is already  site configuration in place
export const check_site_config=async()=>{
     try {
         const response =await api.get("/Configuration/check/")
        return({
            status:response.data.status,
            configuration:response.data.configuration,
            result:response.data.result
        })
     } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
     }
 }

 //update org role from admin side
 export  const  update_org_role= async(id,role,expire_date)=>{
     try{
         const response= await api.put(`/Member/update/role/${id}/${role}`,{
             expire_date,
             role
         })
         return({
            status:response.data.status,
            result:response.data.result
        })
     }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
     }
 }
 //fetching all Membership requests 
 export  const  get_all_request=async()=>{
    try{
        const response= await api.get(`/Request/get/all/`)
        return({
           status:response.data.status,
           request:response.data.request
       })
    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
export const activate_membership_status= async(account_id,status)=>{
  
    try{
        const response=await api.put("/Members/membership-status/update/",{
            account_id,
            status
         
        })
        return({
            status:response.data.status,
            result:response.data.result
        })

    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
//send email to Member about request

export const sent_request_result_email= async(account_id,status,email)=>{
    try{
        const response=await api.post("/Request/result/email",{
            account_id,
            status,
            email
         
        })
        return({
            status:response.data.status,
            msg:response.data.msg
        })

    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
//get all messages 
export  const  get_all_messages=async()=>{
    try{
        const response= await api.get(`/Messages/get-all/`)
        return({
           status:response.data.status,
           messages:response.data.messages
       })
    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
//update message status
export const update_message_status= async(msg_id,account_id)=>{
    try{
        const response= await api.put(`/Messages/update/${msg_id}/${account_id}`)
        return({
           status:response.data.status,
           result:response.data.result
       })
    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
//deactivate announcemnet
export const Deactivate_announcument=async(ac_id)=>{
    try {
        
        const response=await api.put(`/Announcement/Deactivate/${ac_id}`)
        return({
            status:response.data.status,
            result:response.data.result
        })
        
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    } 
}
//get all transactions
export const get_all_transactions=async()=>{
    try{
        const response= await api.get(`/transactions/get-all/`)
        return({
           status:response.data.status,
           transactions:response.data.transaction
       })
    }catch(error){
       console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}
export const get_all_id_request=async()=>{
    try {
        const response= await api.get(`/ID/get-all/request/`)
        return({
           status:response.data.status,
           request:response.data.request
       })
    } catch (error) {
        console.log(error);
       return({
           status:"fail",
           error:error.response.data.error
       })
    }
}