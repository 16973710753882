import React ,{useEffect, useState}from 'react';
import {  useNavigate } from 'react-router-dom';
import TextsmsIcon from '@mui/icons-material/Textsms';
import TitleHeader from '../components/TitleHeader';
import Message_viewTable from '../components/Message_viewTable';
import { get_all_messages } from '../Functions/admin_functions';
import SimpleBackdrop from '../components/SimpleBackdrop';
import RefreshIcon from '@mui/icons-material/Refresh';

function Messages_page() {
const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
const [allMessages,setMessage]=useState(null);
const [filter,setfilter]=useState({
  status:"unread"
})
const navigate=useNavigate();
const fetch_data=(status)=>{

    get_all_messages().then((res)=>{
      if(status=="read")
        {
          setMessage(res.messages && res.messages.filter((msg)=>msg.status=="read"));
        }
        else if(status=="unread")
          {
            setMessage(res.messages && res.messages.filter((msg)=>msg.status=="unread"));
          }
         else if(status=="Both")
            {
              
              setMessage(res.messages && res.messages.filter((msg)=>msg.status=="read"||msg.status=="unread"));
            }
    })
}
const onHandelChange=(e)=>{
  setMessage(null);
  setfilter( (prev)=>({
    ...prev,
    [e.target.name]:e.target.value
  }))
  fetch_data(e.target.value);
 
}

const refresh_data=()=>{
    fetch_data("unread");
    setfilter({
      status:"unread"
    })
}
useEffect(()=>{
    if (account==null)
        {
            navigate("/admin/login");
        }
        else{

          fetch_data("unread");
        }
    },[])
  return (
    <div className='container-fluid'>
        <div className='container-fluid whole_title_container shadow '>
             <TitleHeader icon={<TextsmsIcon/>} title="Messages"/>
        </div>
        <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5'>
               <div className='d flex justify-content-around align-item-center'>
               <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={onHandelChange} required value={filter.status}name='status'>
             
              <option value="unread">unread</option>
              <option value="read">read</option>
              <option value="Both">Both</option>
          </select>
          <label htmlFor="Gender">Status</label>
        </div> 
                
               </div>

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>
               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
          {allMessages ? <Message_viewTable result={allMessages} account={account.account_id} refresh_data={refresh_data} className="text-dark"/>:<SimpleBackdrop/>}
        </div>

      </div>
    </div>
    
  )
}

export default Messages_page